import { Record } from 'immutable';

const defaultValues = {
  events: null,
  contestant: null,
  rankByDisciplines: null,
  myRank: null,
  user: null,
  youthRanks: null,
};

export default class Result extends Record(defaultValues, Result) {
  constructor(values) {
    super({
      ...values,
    });
  }
}
