import api from 'api';
import { getFromState, getAccessToken, logError } from 'helpers';

export const CONTESTANT_DISCIPLINE_BY_SEGMENT_ASYNC_START =
  'CONTESTANT_DISCIPLINE_BY_SEGMENT_ASYNC_START';
export const CONTESTANT_DISCIPLINE_BY_SEGMENT_ASYNC_SUCCESS =
  'CONTESTANT_DISCIPLINE_BY_SEGMENT_ASYNC_SUCCESS';
export const CONTESTANT_DISCIPLINE_BY_SEGMENT_ASYNC_FAIL =
  'CONTESTANTDISCIPLIE_BY_SEGMENT_ASYNC_FAIL';

function contestantDisciplineBySegmentStart() {
  return {
    type: CONTESTANT_DISCIPLINE_BY_SEGMENT_ASYNC_START,
  };
}

function contestantDisciplineBySegmentSuccess(data) {
  return {
    type: CONTESTANT_DISCIPLINE_BY_SEGMENT_ASYNC_SUCCESS,
    data,
  };
}

function contestantDisciplineBySegmentFail(error) {
  return {
    type: CONTESTANT_DISCIPLINE_BY_SEGMENT_ASYNC_FAIL,
    error,
  };
}

function getContestantDisciplinePath(ERAUID, SegmentUID) {
  const basePath = 'contestant-discipline/filters?';
  const ERAUIDSuffix = ERAUID ? `ERAUID=${ERAUID}&` : '';
  const SegmentUIDSuffix = SegmentUID ? `SegmentUID=${SegmentUID}` : '';

  return basePath + ERAUIDSuffix + SegmentUIDSuffix;
}

export function contestantDisciplineBySegment(ERAUID, SegmentUID) {
  return async (dispatch, getState) => {
    const { authPayload } = getFromState(getState, 'auth');
    const accessToken = getAccessToken(authPayload);
    const path = getContestantDisciplinePath(ERAUID, SegmentUID);

    dispatch(contestantDisciplineBySegmentStart());
    try {
      const response = await api({
        path,
        method: 'GET',
        header: {
          Accept: 'application/json',
        },
        accessToken,
      });

      dispatch(
        contestantDisciplineBySegmentSuccess({
          list: response,
        }),
      );
      return response;
    } catch (error) {
      logError(error);
      const err =
        'There was a problem getting the contestant disciplines, please try again.';
      dispatch(contestantDisciplineBySegmentFail(err));
      return null;
    }
  };
}
