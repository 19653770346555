import gql from 'graphql-tag';

export const EVENT_GET = gql`
  query EVENT_GET($id: ID!, $ERAUID: ID) {
    eventGet(id: $id, ERAUID: $ERAUID) {
      id
      EventDate
      BlockWomenSegment
      BlockYouthSegment
      totalNominations
      totalEntries
      entryFee
      EventFormat
      ShouldCollectTaxInfo
      eventType
      eventTypeName
      name
      dateRegistrationStart
      dateRegistrationEnd
      dateStart
      dateEnd
      VenueUID
      eventDrawDate
      firstPerformance
      firstPerformanceRiding
      isSlack
      location
      rank
      rankLabel
      purse
      IsEntryExclusive
      EventName
      SubmissionStatus
      EventRank
      TimeZoneUID
      TimeZoneName
      TimeZoneOffsetInMinutes
      TimezoneNameIana
      CommunicationTextTradeDate
      CommunicationTextStockDraw
      CommunicationTextPostedResults
      SecretaryUID
      SanctionBodyUID
      NumberOfPrefs
      NumberOfNots
      AllowOutIfPrefNotMet
      ContractorUID
      TradeDate
      EventText
      AllowEntry
      IsNomLastPerformance
      EventImage
      EventAthleteAgreementURL
      SecondaryContractors
      Season
      Website
      Facebook
      Instagram
      RequirePreferenceSelection
      ResultsURL
      IsWcraManaged
      ShowStallsLink
      isAlliancePartner
      hasNominations
      DisablePartnerSelection
      EventMessaging
      ShowEntryCountOnPerformances
      Associations {
        AssociationUID
        Name
        ShortName
        CollectMembershipNumber
        CollectMembershipFees
        IsAlliancePartner
        AssociationTypeUID
        AssociationTypeAsString
        AssociationRulesUrl
        AssociationFees {
          AssociationMemberTypeUID
          AssociationUID
          AssociationTypeFeeUID
          AssociationShortName
          MembershipType
          FeeAmount
          IsRenewalFee
          IsPermitFee
          IsNonMember
          AdultOnly
          YouthOnly
        }
        SupportPhone
        createdAt
        updatedAt
      }
      DisciplineFees {
        EventEntryDisciplineFeeUID
        EventUID
        DisciplineUID
        Fee
        Discipline
        IsDisciplineInEvent
        NumberOfGos
        NumberOfProgressives
        SemiFinals
        Finals
        MaxNumberOfEntriesPerAthlete
        PartnerMultipleEntries
        RequirePartnerSelection
        DisciplineFee
        DisciplineFeeName
        BlockWomenSegment
        BlockYouthSegment
        Classes {
          ClassName
          EventEntryDisciplineFeeUID
          EventUID
          DisciplineUID
          Fee
          Discipline
          IsDisciplineInEvent
          NumberOfGos
          NumberOfProgressives
          SemiFinals
          Finals
          MaxNumberOfEntriesPerAthlete
          DisciplineFee
          DisciplineFeeName
          RequirePartnerSelection
          PartnerMultipleEntries
          Division
          DivisionName
          DivisionSortOrder
          DivisionFee
          Nominatable
          BlockWomenSegment
          BlockYouthSegment
        }
      }
      performances {
        PerformanceUID
        EventUID
        PerformanceNum
        PerformanceName
        PerformanceDate
        CompLevel
        CompLevelAsString
        Rot
        Rnd
        IsSlack
        IsNominatable
        EventDate
        EventDateTo
        TimeZoneUID
        isUpcoming
        IsEntryExclusive
        PerformanceDateWithTimezone
        TimezoneAbbreviation
        TimezoneNameIana
        TimezoneOffsetInMinutes
        Disciplines {
          id
          name
          Short
          GoNumber
          PreferenceEligible
          NumberOfAthletes
          NumberOfAnimals
          NumberOfJudges
          StockDrawIsSubmitted
          EventUID
          EventEntryDisciplineFeeUID
          ClassName
          Nominatable
        }
      }
      entryCounts {
        ERAUID
        disciplines {
          compType
          entryCount
          eventEntryDisciplineFeeUID
        }
      }
      AllowClasses
      AllowsPayLater
      AllowsNomination
      AllowsNominationOnEntry
      AllowsBuddyGroup
      nominatableOnEntrySegments {
        SegmentUID
        Name
        StartDate
        EndDate
        NominatableByYouth
      }
      NominationOnEntryDiscountPercentage
      nominationOnEntryFee
      nominationFee
      MaxNumberOfBuddies
      PrimaryAssociationUID
      ShouldCollectSNNAndCitizenshipInfo
    }
  }
`;
