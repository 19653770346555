import moment from 'moment';

export const youthAgeVerify = (DOB) => {
  const formattedDOB = moment(DOB, 'MMDDYYYY').format('YYYY-MM-DD');

  const maxAge = moment()
    .subtract(18, 'years')
    .format('YYYY-MM-DD');

  const minAge = moment()
    .subtract(8, 'years')
    .format('YYYY-MM-DD');

  const isAfter = !moment(formattedDOB).isAfter(maxAge);
  const isBefore = !moment(formattedDOB).isBefore(minAge);

  return isAfter || isBefore;
};
