/**
 * Filter associations with collect membership true and sort by primary association first
 * @param {number} primaryAssociationUID if passed associations will be sorted by primary association first
 * @param {Array} associations
 * @returns {Array} Array of associations with collect membership
 */
const getCollectMembershipAssociations = (
  primaryAssociationUID,
  associations,
) => {
  if (!associations || associations.length === 0) return [];
  let primaryAssociation = associations.find(
    (association) => association.AssociationUID === primaryAssociationUID,
  );

  let sortedAssociations = [];

  if (primaryAssociation) sortedAssociations.push(primaryAssociation);

  const secondaryAssociations = associations.filter(
    (association) => association.AssociationUID !== primaryAssociationUID,
  );

  sortedAssociations = sortedAssociations.concat(secondaryAssociations);

  return sortedAssociations.filter((x) => x.CollectMembershipNumber);
};

export { getCollectMembershipAssociations };
