// @flow
import { merge, extend, pick } from 'lodash';

import Base from 'models/Base';
import { getNestedProperty } from 'helpers';
import {
  ADD_CHILD_REGISTRATION,
  REMOVE_CHILD_REGISTRATION,
  RESET_CHILDREN_REGISTRATION,
  ADD_USER_REGISTRATION,
  RESET_REGISTRATION,
  SET_CURRENT_REGISTRATION_INFO,
  SET_CURRENT_REGISTRATION_CONSENT,
  RESET_CURRENT_REGISTRATION,
} from 'actions/registration';

type ConsentType = {|
  signature: string,
  country: string,
  street: string,
  city: string,
  state: string,
  zip: string,
  isDisclosable: boolean,
|};

type InfoType = {|
  firstName: string,
  lastName: string,
  DOB: string,
  Gender: string,
  disciplineId: string,
|};

type RegistrationActionType = {|
  data: InfoType | ConsentType,
  type: string,
|};

const initialState = {
  loading: false,
  errorMessage: '',
  data: {
    currentAthlete: { info: {}, consent: {} },
    youthAthletes: {},
    user: {},
  },
};

const CHILD_INFO_KEYS = [
  'firstName',
  'lastName',
  'DOB',
  'Gender',
  'disciplineId',
];

const CHILD_CONSENT_KEYS = [
  'signature',
  'country',
  'street',
  'city',
  'state',
  'zip',
  'isDisclosable',
];

const getChildNameKey = (action: RegistrationActionType) => {
  return `${getNestedProperty('data.firstName', action)} ${getNestedProperty(
    'data.lastName',
    action,
  )}`;
};

const actionsMap = {
  [ADD_CHILD_REGISTRATION]: (state, action: RegistrationActionType) => {
    return {
      loading: false,
      errorMessage: '',
      data: merge({}, state.data, {
        youthAthletes: {
          [getChildNameKey(action)]: pick(action.data, [
            ...CHILD_INFO_KEYS,
            ...CHILD_CONSENT_KEYS,
          ]),
        },
      }),
    };
  },
  [REMOVE_CHILD_REGISTRATION]: (state, action: RegistrationActionType) => {
    const youthAthletes = Object.assign({}, state.data.youthAthletes);
    delete youthAthletes[getChildNameKey(action)];
    return {
      loading: false,
      data: extend({}, state.data, { youthAthletes: youthAthletes }),
    };
  },
  [ADD_USER_REGISTRATION]: (state, action: RegistrationActionType) => {
    return {
      loading: false,
      errorMessage: '',
      data: merge({}, state.data, { user: action.data }),
    };
  },
  [RESET_REGISTRATION]: () => {
    return {
      loading: false,
      errorMessage: '',
      data: { user: {}, youthAthletes: {}, currentAthlete: {} },
    };
  },
  [RESET_CHILDREN_REGISTRATION]: (state) => {
    return {
      loading: false,
      data: extend({}, state.data, { youthAthletes: {} }),
    };
  },
  [SET_CURRENT_REGISTRATION_INFO]: (state, action) => {
    return {
      loading: false,
      errorMessage: '',
      data: merge({}, state.data, {
        currentAthlete: { info: pick(action.data, CHILD_INFO_KEYS) },
      }),
    };
  },
  [SET_CURRENT_REGISTRATION_CONSENT]: (state, action) => {
    return {
      loading: false,
      errorMessage: '',
      data: merge({}, state.data, {
        currentAthlete: { consent: pick(action.data, CHILD_CONSENT_KEYS) },
      }),
    };
  },
  [RESET_CURRENT_REGISTRATION]: (state) => {
    return {
      loading: false,
      data: extend({}, state.data, {
        currentAthlete: Object.assign({}, initialState.data.currentAthlete),
      }),
    };
  },
};

export default function registration(
  state: Base = initialState,
  action: RegistrationActionType,
) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
