import { Record } from 'immutable';

const defaultValues = {
  ERAUID: null,
  id: null,
  firstName: '',
  lastName: '',
  disciplineId: null,
  photoFileName: null,
  street: null,
  street2: null,
  city: null,
  state: null,
  zip: null,
  country: null,
  socialTags: null,
  phone: null,
  status: null,
  updatedAt: null,
  DOB: null,
  DOBVerified: null,
  Gender: null,
  nickname: null,
  hometown: null,
  careerEarnings: null,
  worldChampionshipEarned: null,
  height: null,
  weight: null,
  facebook: null,
  twitter: null,
  instagram: null,
  LegalFirstName: null,
  LegalLastName: null,
  SSN: null,
  USCitizen: 1,
  isAthlete: true,
  signature: '',
  isDisclosable: false,
};

export default class YouthAthlete extends Record(
  defaultValues,
  'YouthAthlete',
) {
  constructor(values) {
    super({
      ...values,
    });
  }
}
