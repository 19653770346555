// @flow
import { getNestedProperty } from './';

import type { EventType } from 'models/Event';
import type { EntryType } from 'models/Entry';

export const isEntryPerformanceInEvent = (
  entry: EntryType,
  event: EventType,
) => {
  const performances = getNestedProperty('performances', event, []);
  if (performances.length === 0) {
    return false;
  }
  return performances.some(
    (performance) =>
      String(getNestedProperty('Pref1', entry)) ===
        String(performance.PerformanceUID) ||
      String(getNestedProperty('Pref2', entry)) ===
        String(performance.PerformanceUID) ||
      String(getNestedProperty('Pref3', entry)) ===
        String(performance.PerformanceUID) ||
      String(getNestedProperty('Out1', entry)) ===
        String(performance.PerformanceUID) ||
      String(getNestedProperty('Out2', entry)) ===
        String(performance.PerformanceUID) ||
      String(getNestedProperty('PerformanceUID', entry)) ===
        String(performance.PerformanceUID),
  );
};
