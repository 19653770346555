export const getEntryDiscFee = (
  entryFees = [],
  disciplineId = '',
  customFee = false,
) => {
  const discipline = entryFees.find(
    (entry) => entry.disciplineId === disciplineId,
  );

  if (!discipline) return 0;

  return customFee ? discipline.disciplineFee : discipline.entryFee;
};
