// @flow
import { Record } from 'immutable';

export type UserType = {
  ERAUID: ?number,
  id: ?number,
  fullName?: string,
  firstName: ?string,
  lastName: ?string,
  email: ?string,
  disciplineId: ?number,
  photoFileName: ?number,
  street: ?string,
  street2: ?string,
  city: ?string,
  state: ?string,
  zip: ?string,
  country: ?string,
  socialTags: ?string,
  lastLogin: ?string,
  phone: ?string,
  status: ?string,
  updatedAt: ?string,
  DOB: ?string,
  Gender: ?String,
  nickname: ?string,
  hometown: ?string,
  careerEarnings: ?string,
  worldChampionshipEarned: ?string,
  height: ?string,
  weight: ?string,
  facebook: ?string,
  twitter: ?string,
  instagram: ?string,
  nominations: ?(Object[]),
  entryData: Object,
  restrictionData: Object,
  youthAthletes: ?(Object[]),
  LegalFirstName: ?string,
  LegalLastName: ?string,
  SSN: ?string,
  USCitizen: ?boolean,
  isParent: ?boolean,
  isAthlete: ?boolean,
  TaxInfoUpdateDate: ?Date,
  SSNLastFour?: ?string,
  DOBVerified?: ?boolean,
};

const defaultValues = {
  ERAUID: null,
  DOBVerified: null,
  id: null,
  firstName: '',
  lastName: '',
  email: '',
  disciplineId: null,
  photoFileName: null,
  street: null,
  street2: null,
  city: null,
  state: null,
  zip: null,
  country: null,
  socialTags: null,
  lastLogin: null,
  phone: null,
  status: null,
  updatedAt: null,
  DOB: null,
  Gender: null,
  nickname: null,
  hometown: null,
  careerEarnings: null,
  worldChampionshipEarned: null,
  height: null,
  weight: null,
  facebook: null,
  twitter: null,
  instagram: null,
  nominations: [],
  entryData: {},
  restrictionData: {},
  youthAthletes: [],
  LegalFirstName: null,
  LegalLastName: null,
  SSN: null,
  USCitizen: 1,
  isParent: null,
  isAthlete: null,
  TaxInfoUpdateDate: null,
  SSNLastFour: null,
};

export default class User extends Record(defaultValues, User) {
  constructor(values: ?UserType) {
    super({
      ...values,
    });
  }
}
