// @flow
import { Record } from 'immutable';
import type { EntryType } from 'models/Entry';
import type { AssociationTypeFee } from 'models/AssociationMembership';

export type BuddyType = {|
  ERAUID: string,
  FullName: string,
  TotalAmountPaid: string,
  PayLater: boolean,
  createdAt: string,
  CompType: number,
  entries: Array<EntryType>,
  AssociationFees: AssociationTypeFee[],
  associationMembers: Array<Object>,
|};

export type BuddyGroupType = Array<BuddyType>;

const defaultValues: BuddyType = {
  ERAUID: '',
  FullName: '',
  TotalAmountPaid: '',
  PayLater: false,
  createdAt: '',
  CompType: 0,
  entries: [],
  AssociationFees: [],
  associationMembers: [],
};

export default class Buddy extends Record(defaultValues, 'Buddy') {
  constructor(values: ?BuddyType) {
    super({ ...values });
  }
}
