// @flow
import React, { useEffect } from 'react';

import type { Node } from 'react';
import { initFeatureFlag } from '../../utils/flagsmith';

type FeatureFlagConfigProps = {|
  children: Node,
|};

const FeatureFlagConfig = ({ children }: FeatureFlagConfigProps) => {
  const [
    isFeatureFlagInitialized,
    setIsFeatureFlagInitialized,
  ] = React.useState(false);
  useEffect(() => {
    initFeatureFlag().then(() => {
      setIsFeatureFlagInitialized(true);
    });
  }, []);
  return isFeatureFlagInitialized && <div>{children}</div>;
};

export default FeatureFlagConfig;
