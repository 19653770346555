// @flow
import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';

import AsyncComponentHOC from 'components/HOC/AsyncComponentHOC';
import App from 'containers/App';
import { baseRoutes } from 'constants/routes/componentMap';
import { history } from 'helpers';

const AsyncNotFound = AsyncComponentHOC(() => import('components/NotFound'));
const AsyncEntryHome = AsyncComponentHOC(() => import('components/EntryHome'));
const AsyncNominationHome = AsyncComponentHOC(() =>
  import('components/NominationHome'),
);
const AsyncBaseHome = AsyncComponentHOC(() => import('components/BaseHome'));
const AsyncGiftCardHome = AsyncComponentHOC(() =>
  import('components/GiftCardHome'),
);

const Routes = ({ routeProps }: Object) => {
  return (
    <Router history={history}>
      <App>
        <Switch>
          <Route
            path="/gift-card/"
            render={(p) => <AsyncGiftCardHome {...p} {...routeProps} />}
          />
          <Route
            render={(p) => <AsyncNominationHome {...p} {...routeProps} />}
            path="/nominate/"
          />
          <Route
            path="/event/"
            render={(p) => <AsyncEntryHome {...p} {...routeProps} />}
          />
          <Route
            path={baseRoutes().map((route) => route.path)}
            exact
            render={(p) => <AsyncBaseHome {...p} {...routeProps} />}
          />
          <Route component={AsyncNotFound} />
        </Switch>
      </App>
    </Router>
  );
};

export default Routes;
