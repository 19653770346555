import flagsmith from 'flagsmith';

export const initFeatureFlag = () =>
  flagsmith.init({
    environmentID: process.env.REACT_APP_FLAGSMITH_ENV,
    cacheFlags: true,
  });

export const hasFeatureFlag = (flag) => flagsmith && flagsmith.hasFeature(flag);
export const getAllFeatures = () => flagsmith.getAllFlags();
