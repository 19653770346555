import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  appliedGiftCardBalances: [],
};

export const giftcardBalancesSlice = createSlice({
  name: 'giftCardBalances',
  initialState: initialState,
  reducers: {
    saveAppliedGiftCardBalances: (state, action) => {
      state.appliedGiftCardBalances = action.payload;
    },
    clearGiftCardBalances: () => initialState,
  },
});

export const {
  saveAppliedGiftCardBalances,
  clearGiftCardBalances,
} = giftcardBalancesSlice.actions;

export default giftcardBalancesSlice.reducer;
