import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { CaptureConsole } from '@sentry/integrations';

// Don't enable sentry in a local environment
if (process.env.SENTRY_ENV !== 'local') {
  Sentry.init({
    // Sentry project DSN.
    dsn: process.env.SENTRY_DSN,
    // Integrations.
    integrations: [
      new CaptureConsole({ levels: ['error'] }),
      new BrowserTracing({
        startTransactionOnLocationChange: true,
        startTransactionOnPageLoad: true,
      }),
    ],
    // This should not be enabled locally.
    enabled: ['development', 'stage', 'production'].includes(
      process.env.SENTRY_ENV,
    ),
    // This defaults to 100 based on the docs, though we don't need that much
    // https://docs.sentry.io/platforms/javascript/guides/vue/configuration/options/#max-breadcrumbs
    maxBreadcrumbs: 10,
    // How deep into nested objects sentry will traverse.
    // This defaults to 3 based on the docs, we might need a bit more though
    // https://docs.sentry.io/platforms/javascript/guides/vue/configuration/options/#normalize-depth
    normalizeDepth: 5,
    // https://docs.sentry.io/platforms/javascript/configuration/options/#release
    release: `${process.env.SENTRY_ENV}-${process.env.SOURCE_VERSION}`,
    // https://docs.sentry.io/platforms/javascript/configuration/options/#environment
    environment: process.env.SENTRY_ENV,
    // Enable tracing/performance tracking only in production.
    // https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
    tracesSampleRate: 0.1,
  });
}
