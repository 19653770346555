import api from 'api';
import { getFromState, getAccessToken, logError } from 'helpers';

export const LEADERBOARD_ASYNC_START = 'LEADERBOARD_ASYNC_START';
export const LEADERBOARD_ASYNC_SUCCESS = 'LEADERBOARD_ASYNC_SUCCESS';
export const LEADERBOARD_ASYNC_FAIL = 'LEADERBOARD_ASYNC_FAIL';

function leaderboardAsyncStart() {
  return {
    type: LEADERBOARD_ASYNC_START,
  };
}

function leaderboardAsyncSuccess(data) {
  return {
    type: LEADERBOARD_ASYNC_SUCCESS,
    data,
  };
}

function leaderboardAsyncFail(error) {
  return {
    type: LEADERBOARD_ASYNC_FAIL,
    error,
  };
}

function getLeaderboardPath(season, discipline, segment, type) {
  const basePath = 'contestants/leaders?';
  const seasonSuffix = season ? `season=${season}&` : '';
  const disciplineSuffix = discipline ? `discipline=${discipline}&` : '';
  const segmentSuffix = segment ? `segment=${segment}&` : '';
  const typeSuffix = type ? `type=${type}&` : '';

  return (
    basePath + seasonSuffix + disciplineSuffix + segmentSuffix + typeSuffix
  );
}

export function getLeaderboard(values) {
  return async (dispatch, getState) => {
    const { season, discipline, segment, type } = values;
    const { authPayload } = getFromState(getState, 'auth');
    const accessToken = getAccessToken(authPayload);
    const path = getLeaderboardPath(season, discipline, segment, type);

    dispatch(leaderboardAsyncStart());
    try {
      const response = await api({
        path,
        method: 'GET',
        header: {
          Accept: 'application/json',
        },
        accessToken,
      });

      dispatch(leaderboardAsyncSuccess({ leaders: response }));
      return response;
    } catch (error) {
      logError(error);
      const err = 'There was a problem getting leader board, please try again.';
      dispatch(leaderboardAsyncFail(err));
      return null;
    }
  };
}
