import { Auth0Provider } from '@auth0/auth0-react';
import { Provider } from 'react-redux';
import { StripeProvider } from 'react-stripe-elements';
import { PersistGate } from 'redux-persist/integration/react';
import { ApolloProvider } from '@apollo/react-hooks';
import client from './api/apollo';
import { ToastProvider } from 'react-toast-notifications';
import Toast from './components/Toast';
import Root from './containers/Root';
import ErrorBoundary from './containers/App/ErrorBoundary';
import React from 'react';
import configureStore from './store/configureStore';
const { store, persistor } = configureStore();

const stripePubKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

const auth0Config = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  redirectUri: `${window.location.origin}/auth0`,
};

export const App = () => {
  return (
    <ErrorBoundary>
      <Auth0Provider
        domain={auth0Config.domain}
        clientId={auth0Config.clientId}
        audience={auth0Config.audience}
        useRefreshTokens
        cacheLocation="localstorage"
        redirectUri={auth0Config.redirectUri}
      >
        <Provider store={store}>
          <StripeProvider apiKey={stripePubKey}>
            <PersistGate loading={null} persistor={persistor}>
              <ApolloProvider client={client}>
                <ToastProvider
                  autoDismiss
                  placement="top-center"
                  components={{ Toast }}
                >
                  <Root store={store} />
                </ToastProvider>
              </ApolloProvider>
            </PersistGate>
          </StripeProvider>
        </Provider>
      </Auth0Provider>
    </ErrorBoundary>
  );
};

if (window.Cypress) {
  window.store = store;
}
