import UserNominations from 'models/UserNominations';
import Base from 'models/Base';

import {
  NOMINATIONS_ASYNC_START,
  NOMINATIONS_ASYNC_STOP,
  NOMINATIONS_ASYNC_SUCCESS,
  NOMINATIONS_ASYNC_FAIL,
} from 'actions/userNominations';

const initialState = new Base({
  loading: false,
  errorMessage: '',
  data: new UserNominations(),
});

const actionsMap = {
  [NOMINATIONS_ASYNC_START]: (state) => {
    return state.merge({
      loading: true,
      errorMessage: '',
    });
  },
  [NOMINATIONS_ASYNC_STOP]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
    });
  },
  [NOMINATIONS_ASYNC_SUCCESS]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: null,
      data: new UserNominations({ nominations: action.data }),
    });
  },
  [NOMINATIONS_ASYNC_FAIL]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.error,
    });
  },
};

export default function userNominations(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
