import { isUserFemale } from './isUserFemale';
import { isUserYouth } from './isUserYouth';

export const getUserAthleteOption = (user) => {
  const isYouthAthlete = isUserYouth(user.DOB);
  const isFemaleAthlete = isUserFemale(user.Gender);
  return {
    value: user.ERAUID,
    isAthlete: user.isAthlete,
    label: `${user.firstName} ${user.lastName}`,
    isYouthAthlete,
    isFemaleAthlete,
  };
};
