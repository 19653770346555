import { Record } from 'immutable';

const defaultValues = {
  AdminSettingUID: null,
  Property: null,
  PropertyType: null,
  Value: null,
};

export default class AdminSetting extends Record(defaultValues, AdminSetting) {
  constructor(values) {
    const getValue = (setting) => {
      if (setting) {
        if (setting.PropertyType === 'boolean') {
          return setting.Value === '1';
        }
        return setting.Value;
      }
      return null;
    };
    super({
      ...values,
      Value: getValue(values),
    });
  }
}
