import User from 'models/User';
import Base from 'models/Base';

import {
  USER_ASYNC_START,
  USER_ASYNC_FAIL,
  USER_ASYNC_SUCCESS,
  USER_ASYNC_CLEAR_ERROR,
} from 'actions/user';

import { REHYDRATE } from 'redux-persist/src/constants';

import {
  REMOVE_AS_PARTNER_SUCCESS,
  REMOVE_AS_PARTNER_FAIL,
} from 'actions/user';

const initialState = new Base({
  loading: false,
  errorMessage: '',
  data: new User(),
});

const actionsMap = {
  [USER_ASYNC_CLEAR_ERROR]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
    });
  },
  [USER_ASYNC_START]: (state) => {
    return state.merge({
      loading: true,
      errorMessage: '',
    });
  },
  [USER_ASYNC_FAIL]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.errorMessage,
    });
  },
  [USER_ASYNC_SUCCESS]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: null,
      data: new User(action.data),
    });
  },
  [REHYDRATE]: (state, action) => {
    const payload = action.payload;
    const userPayload = payload ? payload.user : null;

    if (userPayload) {
      return new Base({
        loading: false,
        errorMessage: '',
        data: new User(userPayload.data),
      });
    }
    return new Base({
      loading: false,
      errorMessage: '',
      data: new User(),
    });
  },
  [REMOVE_AS_PARTNER_SUCCESS]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
    });
  },
  [REMOVE_AS_PARTNER_FAIL]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.error,
    });
  },
};

export default function user(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
