// @flow

export const putRankInYouthAthletesList = (
  youthAthletes: ?(Object[]),
  leaderboard: ?(Object[]),
): Object[] => {
  if (youthAthletes && leaderboard) {
    return youthAthletes.map((youthAthlete) => {
      youthAthlete.rank = leaderboard.filter(
        (contestant) => contestant.ERAUID == youthAthlete.ERAUID,
      );
      return youthAthlete;
    });
  }
  return [];
};
