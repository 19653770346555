import api from 'api';
import { getAccessToken, getFromState, logError } from 'helpers';

export const LIST_ADMIN_SETTINGS_FAIL = 'LIST_ADMIN_SETTINGS_FAIL';
export const LIST_ADMIN_SETTINGS_SUCCESS = 'LIST_ADMIN_SETTINGS_SUCCESS';
export const SELECT_ADMIN_SETTING_SUCCESS = 'SELECT_ADMIN_SETTING_SUCCESS';

export function listAdminSettingsFail(errorMessage) {
  return {
    type: LIST_ADMIN_SETTINGS_FAIL,
    errorMessage,
  };
}

export function listAdminSettingsSuccess(data) {
  return {
    type: LIST_ADMIN_SETTINGS_SUCCESS,
    data,
  };
}

export function selectAdminSettingSuccess(data) {
  return {
    type: SELECT_ADMIN_SETTING_SUCCESS,
    data,
  };
}

export function listAdminSettings() {
  return async (dispatch, getState) => {
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getAccessToken(authPayload);

      const response = await api({
        path: 'adminSetting/nominationSetting',
        method: 'GET',
        accessToken,
      });
      dispatch(listAdminSettingsSuccess(response.data));
      return response;
    } catch (error) {
      logError(error);
      dispatch(listAdminSettingsFail(error.message));
      return null;
    }
  };
}

export function selectAdminSetting(name) {
  return (dispatch) => {
    dispatch(selectAdminSettingSuccess(name));
  };
}
