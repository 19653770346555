import api from 'api';
import { getFromState, logError } from 'helpers';
import { getAccessToken } from '../helpers';

export const EVENT_TYPE_ASYNC_START = 'EVENT_TYPE_ASYNC_START';
export const EVENT_TYPE_ASYNC_SUCCESS = 'EVENT_TYPE_ASYNC_SUCCESS';
export const EVENT_TYPE_LIST_ASYNC_SUCCESS = 'EVENT_TYPE_LIST_ASYNC_SUCCESS';
export const EVENT_TYPE_ASYNC_FAIL = 'EVENT_TYPE_ASYNC_FAIL';

function eventTypeAsyncStart() {
  return {
    type: EVENT_TYPE_ASYNC_START,
  };
}

export function eventTypeAsyncSuccess() {
  return {
    type: EVENT_TYPE_ASYNC_SUCCESS,
  };
}

export function eventTypeListAsyncSuccess(data) {
  return {
    type: EVENT_TYPE_LIST_ASYNC_SUCCESS,
    data,
  };
}

function eventTypeAsyncFail(errorMessage) {
  return {
    type: EVENT_TYPE_ASYNC_FAIL,
    errorMessage,
  };
}

export function listEventTypes() {
  return async (dispatch, getState) => {
    dispatch(eventTypeAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getAccessToken(authPayload);

      const response = await api({
        path: 'event-types',
        method: 'GET',
        accessToken,
      });

      dispatch(eventTypeListAsyncSuccess(response));
      return response;
    } catch (error) {
      logError(error);
      dispatch(eventTypeAsyncFail(error));
      return null;
    }
  };
}
