import api from 'api';
import client from 'api/apollo';
import { REGISTER_USER } from 'mutations/RegisterUser';
import { AUTHENTICATE_USER } from 'mutations/AuthenticateUser';
import { getFromState, getGraphQlError, logError } from 'helpers';

import { userAsyncSuccess, userAsyncStart, userAsyncFail } from './user';
import { LocalStorageCache } from '@auth0/auth0-react';
import { omitKeys } from '../helpers';
import { EDIT_USER_EMAIL_AUTH0 } from '../mutations/EditUserEmailAuth0';

export const AUTH_ASYNC_START = 'AUTH_ASYNC_START';
export const AUTH_ASYNC_FAIL = 'AUTH_ASYNC_FAIL';
export const AUTH_ASYNC_SUCCESS = 'AUTH_ASYNC_SUCCESS';
export const AUTH_AUTH0_ASYNC_START = 'AUTH_AUTH0_ASYNC_START';
export const AUTH_AUTH0_ASYNC_FAIL = 'AUTH_AUTH0_ASYNC_FAIL';
export const AUTH_AUTH0_ASYNC_SUCCESS = 'AUTH_AUTH0_ASYNC_SUCCESS';
export const AUTH_ACTION_FINISH = 'AUTH_ACTION_FINISH';
export const AUTH_SHOW_INFO_NOTIFICATION = 'AUTH_SHOW_INFO_NOTIFICATION';
export const DE_AUTH = 'DE_AUTH';
export const AUTH_ASYNC_CLEAR_ERROR = 'AUTH_ASYNC_CLEAR_ERROR';

function authAsyncStart() {
  return {
    type: AUTH_ASYNC_START,
  };
}

export function authAsyncFail(error) {
  return {
    type: AUTH_ASYNC_FAIL,
    error,
  };
}

function authActionFinish(error) {
  return {
    type: AUTH_ACTION_FINISH,
    error,
  };
}

export function auth0AsyncFail(error) {
  return {
    type: AUTH_ASYNC_FAIL,
    error,
  };
}

function authAsyncSuccess(data) {
  return {
    type: AUTH_ASYNC_SUCCESS,
    data,
  };
}

function deAuthSuccess() {
  return {
    type: DE_AUTH,
  };
}

export function authAsyncClearError() {
  return {
    type: AUTH_ASYNC_CLEAR_ERROR,
  };
}

export function authShowInfoNotification(show) {
  return {
    type: AUTH_SHOW_INFO_NOTIFICATION,
    show,
  };
}

export function updateEmailAuth0(values) {
  return async (dispatch, getState) => {
    dispatch(userAsyncStart());
    try {
      const userFields = omitKeys(values, [
        'entries',
        'isAthlete',
        'isParent',
        'lastLogin',
        'nominations',
        'youthAthletes',
        'entryData',
        'SSNLastFour',
        'restrictionData',
      ]);
      const currentUser = getFromState(getState, 'user');
      const id = currentUser.id;

      const input = {
        id,
        ...userFields,
      };
      const payload = {
        mutation: EDIT_USER_EMAIL_AUTH0,
        variables: { input },
      };

      const {
        data: {
          editUserEmailAuth0,
          editUserEmailAuth0: { error },
        },
      } = await client.mutate(payload);

      if (error) {
        throw new Error(error);
      }
      const { user, token } = editUserEmailAuth0;

      dispatch(userAsyncSuccess({ ...user, ...values }));
      await dispatch(authAsyncSuccess({ token }));
      return user;
    } catch (error) {
      logError(error);
      let err = 'An error occurred while trying to update your account';

      if (error.message.indexOf('Password is not correct') >= 0) {
        err += '. Password is not correct';
      }

      if (
        error.message.indexOf('Email already in use') >= 0 ||
        error.message.indexOf('The specified new email already exists') >= 0
      ) {
        err += '. Email already in use';
      }

      if (error.message.indexOf('Validation Error') >= 0) {
        err += ' Try using a different email';
      }

      dispatch(userAsyncFail(err));
      return null;
    }
  };
}

export async function authenticationRefresh(dispatch, getState) {
  const { authPayload } = getFromState(getState, 'auth');
  const { email } = getFromState(getState, 'user');
  const { refreshToken } = authPayload.token;
  dispatch(authAsyncStart());
  try {
    const response = await api({
      path: 'auth/refresh-token',
      method: 'POST',
      body: {
        email,
        refreshToken,
      },
    });
    const { token } = response;
    dispatch(authAsyncSuccess({ token }));
    return response;
  } catch (error) {
    logError(error);
    dispatch(authAsyncFail('Your session has expired. Please log in'));
    return null;
  }
}

export async function authenticationRefreshAuth0(dispatch, getState) {
  const { authPayload } = getFromState(getState, 'auth');
  const { refreshToken } = authPayload.token;
  const { email } = getFromState(getState, 'user');
  dispatch(authAsyncStart());
  try {
    const response = await api({
      path: 'auth/refresh-token-auth0',
      method: 'POST',
      body: {
        refreshToken,
        email,
      },
    });
    const { token } = response;
    dispatch(authAsyncSuccess({ token }));
    return response;
  } catch (error) {
    logError(error);
    dispatch(authAsyncFail('Your session has expired. Please log in'));
    return null;
  }
}

export const auth0ValidateToken = (token) => async (dispatch) => {
  dispatch(authAsyncStart());
  try {
    const response = await api({
      path: 'auth/auth0-validation',
      method: 'get',
      accessToken: token,
    });
    const { token: tokenInfo, user } = response;
    const storageCache = new LocalStorageCache();
    const key = storageCache.allKeys().find((key) => key.includes('auth0spa'));
    const refreshTokenInfo = storageCache.get(key);
    if (!tokenInfo) {
      dispatch(authAsyncFail('invalid User'));
      return null;
    }
    const refreshToken = refreshTokenInfo.body.refresh_token;
    await dispatch(authAsyncSuccess({ token: { ...tokenInfo, refreshToken } }));
    await dispatch(userAsyncSuccess(user));
    return response;
  } catch (error) {
    logError(error);
    dispatch(authAsyncFail('Your session has expired. Please log in'));
    return null;
  }
};

export function registerUser(input) {
  return async (dispatch) => {
    dispatch(authAsyncStart());
    try {
      const payload = {
        mutation: REGISTER_USER,
        variables: { input },
      };
      const response = await client.mutate(payload).then(({ data }) => {
        return data.registerUser;
      });

      const { token, user, error } = response;
      if (!token) throw Error(error);
      dispatch(authAsyncSuccess({ token }));
      dispatch(userAsyncSuccess(user));
      return response;
    } catch (error) {
      logError(error);
      const err = getGraphQlError(error);
      dispatch(authAsyncFail(err));
      return null;
    }
  };
}

export function requestPasswordReset({ email, theme }) {
  return async (dispatch) => {
    dispatch(authAsyncStart());
    try {
      await api({
        path: 'auth/password/forgot',
        method: 'POST',
        body: {
          email,
          theme,
        },
      });

      dispatch(authActionFinish());
      return 'Your password reset request has been received, please check your email inbox!';
    } catch (error) {
      logError(error);
      const err = 'This email is not registered';
      dispatch(authAsyncFail(err));
      return null;
    }
  };
}

export function resetPassword(values) {
  return async (dispatch) => {
    dispatch(authAsyncStart());
    try {
      const response = await api({
        path: `auth/password/reset?token=${values.token}`,
        method: 'POST',
        body: {
          newPassword: values.newPassword,
        },
      });

      const { token, user } = response;
      dispatch(authAsyncSuccess({ token }));
      dispatch(userAsyncSuccess(user));
      return response;
    } catch (error) {
      logError(error);
      const err = 'Invalid token';
      dispatch(authActionFinish(err));
      return null;
    }
  };
}

export function authenticateUser(email, password) {
  return async (dispatch) => {
    dispatch(authAsyncStart());
    dispatch(userAsyncStart());
    try {
      const input = { email, password };
      const payload = {
        mutation: AUTHENTICATE_USER,
        variables: { input },
      };

      const response = await client
        .mutate(payload)
        .then(({ data }) => data.loginUser);

      const { token, user, error } = response;
      if (!token) throw Error(error);

      dispatch(authAsyncSuccess({ token }));
      dispatch(userAsyncSuccess(user));

      return response;
    } catch (error) {
      logError(error);
      const err = 'Incorrect email or password';
      dispatch(authAsyncFail(err));
      return null;
    }
  };
}

export function deauthenticateUser() {
  return (dispatch) => {
    return dispatch(deAuthSuccess());
  };
}

export function clearAuthState() {
  return (dispatch) => {
    return dispatch(authAsyncFail(''));
  };
}
