import api from 'api';
import client from 'api/apollo';
import { get } from 'lodash';

import { EDIT_USER } from 'mutations/EditUser';
import {
  getFromState,
  omitKeys,
  omitTypename,
  getAccessToken,
  getGraphQlError,
  logError,
} from 'helpers';

import { REMOVE_AS_PARTNER } from 'mutations/RemoveAsPartner';

export const USER_ASYNC_START = 'USER_ASYNC_START';
export const USER_ASYNC_FAIL = 'USER_ASYNC_FAIL';
export const USER_ASYNC_SUCCESS = 'USER_ASYNC_SUCCESS';
export const USER_ASYNC_CLEAR_ERROR = 'USER_ASYNC_CLEAR_ERROR';
export const ENTRY_REMOVE_AS_PARTNER = 'ENTRY_REMOVE_AS_PARTNER';
export const REMOVE_AS_PARTNER_SUCCESS = 'REMOVE_AS_PARTNER_ACTION_SUCCESS';
export const REMOVE_AS_PARTNER_FAIL = 'REMOVE_AS_PARTNER_ACTION_FAIL';

export function userAsyncStart() {
  return {
    type: USER_ASYNC_START,
  };
}

export function userAsyncFail(errorMessage) {
  return {
    type: USER_ASYNC_FAIL,
    errorMessage,
  };
}

export function userAsyncSuccess(data) {
  return {
    type: USER_ASYNC_SUCCESS,
    data,
  };
}

export function userAsyncClearError() {
  return {
    type: USER_ASYNC_CLEAR_ERROR,
  };
}

export function updateUser(values) {
  return async (dispatch, getState) => {
    dispatch(userAsyncStart());
    try {
      const userFields = omitKeys(values, [
        'entries',
        'isAthlete',
        'isParent',
        'lastLogin',
        'nominations',
        'youthAthletes',
        'entryData',
        'SSNLastFour',
        'restrictionData',
      ]);
      const currentUser = getFromState(getState, 'user');
      const id = currentUser.id;

      const input = {
        id,
        ...userFields,
      };

      const payload = {
        mutation: EDIT_USER,
        variables: { input },
      };

      const { data, error } = await client.mutate(payload);

      if (error) {
        throw new Error(error);
      }
      const { user } = data.editUser;

      dispatch(userAsyncSuccess({ ...user, ...values }));
      return user;
    } catch (error) {
      logError(error);
      let err = 'An error occurred while trying to update your account';

      if (error.message.indexOf('Email already in use') >= 0) {
        err += '. Email already in use';
      }

      if (error.message.indexOf('Validation Error') >= 0) {
        err += ' Try using a different email';
      }

      dispatch(userAsyncFail(err));
      return null;
    }
  };
}

export function updateUserPassword(values) {
  return async (dispatch, getState) => {
    dispatch(userAsyncStart());
    try {
      const { id } = getFromState(getState, 'user');
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getAccessToken(authPayload);

      const response = await api({
        path: `users/${id}/password`,
        method: 'PUT',
        body: values,
        accessToken,
      });

      dispatch(userAsyncSuccess(response));
      return response;
    } catch (error) {
      logError(error);
      let err = 'An error occurred while trying to update your password';

      if (error.message.indexOf('Validation Error') >= 0) {
        err = 'Incorrect current password';
      }
      dispatch(userAsyncFail(err));
      return null;
    }
  };
}

export function updateYouthAthleteInfo(values) {
  return async (dispatch, getState) => {
    dispatch(userAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getAccessToken(authPayload);

      const response = await api({
        path: 'users/update-youth-athlete-info',
        method: 'POST',
        body: values,
        accessToken,
      });

      const userFields = omitKeys(values, [
        'hometown',
        'parentERAUID',
        'parentID',
        'youthAthleteERAUID',
        'TaxInfoUpdateDate',
      ]);

      if (!Array.isArray(response)) {
        throw new Error('Cannot update youth athlete info');
      }

      const currentUser = getFromState(getState, 'user');

      const data = {
        ...currentUser,
        ...userFields,
        youthAthletes: response,
      };

      dispatch(userAsyncSuccess(data));
      return data;
    } catch (error) {
      logError(error);
      const err = 'An error occurred while trying to update youth athlete info';
      dispatch(userAsyncFail(err));
      return null;
    }
  };
}

export function updateUserPhoto(image) {
  return async (dispatch, getState) => {
    dispatch(userAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const { id } = getFromState(getState, 'user');
      const accessToken = getAccessToken(authPayload);

      const body = new FormData();
      body.append('file', image);
      const response = await api({
        path: `users/${id}/photo`,
        method: 'POST',
        body,
        accessToken,
        cType: 'multipart/form-data',
      });

      dispatch(userAsyncSuccess(response));
      return response;
    } catch (error) {
      logError(error);
      dispatch(
        userAsyncFail('File must have jpg or png format and be under 2mb'),
      );
      return null;
    }
  };
}

export function fetchUserByToken(query, token) {
  return async (dispatch) => {
    dispatch(userAsyncStart());
    try {
      const queryPayload = {
        query,
        variables: { token },
        fetchPolicy: 'network-only',
      };
      const response = await client
        .query(queryPayload)
        .then(({ data }) => data.user);

      const { youthAthletes } = response;

      if (youthAthletes && youthAthletes.length > 0) {
        response.youthAthletes = youthAthletes.map(omitTypename);
      }

      dispatch(userAsyncSuccess(response));
      return response;
    } catch (error) {
      logError(error);
      dispatch(userAsyncFail('Youth athletes not found'));
    }
  };
}

function removeAsPartnerSuccess(data) {
  return {
    type: REMOVE_AS_PARTNER_SUCCESS,
    data,
  };
}

function removeAsPartnerFailure(data) {
  return {
    type: REMOVE_AS_PARTNER_FAIL,
    data,
  };
}

export function entryRemoveAsPartner(activeDiscipline, user) {
  return async (dispatch) => {
    dispatch(userAsyncStart());

    const input = {
      disciplineId: activeDiscipline.value,
      EPUID: activeDiscipline.EPUID,
      ERAUID: user.ERAUID,
      shouldCancelEntry: true,
    };

    const payload = {
      mutation: REMOVE_AS_PARTNER,
      variables: { input },
    };

    try {
      const response = await client.mutate(payload);
      dispatch(
        removeAsPartnerSuccess(get(response, 'data.editMyEntryPartner')),
      );
    } catch (error) {
      dispatch(removeAsPartnerFailure(getGraphQlError(error)));
    }
  };
}
