import React from 'react';
import * as Sentry from '@sentry/react';
import { getTheme } from 'helpers';

const theme = getTheme();
export default class ErrorBoundary extends React.Component {
  static getDerivedStateFromError() {
    // Update state so the next render will show the error content.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
  }

  goHome() {
    this.setState({ hasError: false });
    window.location = '/';
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-bundary">
          <h1 className={theme}>OOPS! SOMETHING WENT WRONG</h1>
          <p className={theme}>
            We suggest you go to the homepage while we fix the problem
          </p>
          <button onClick={() => this.goHome()} className="btn-main">
            BACK TO HOME
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}
