import gql from 'graphql-tag';
import { ContestantFragments } from 'fragments/Contestant';

export const GET_CONTESTANT_BUDDIES_FOR_EVENT = gql`
  query GET_CONTESTANT_BUDDIES_FOR_EVENT($ERAUID: ID, $EventUID: ID) {
    getContestantBuddiesForEvent(ERAUID: $ERAUID, EventUID: $EventUID) {
      buddyGroupContestants {
        ...ContestantBuddyInfo
      }
      buddyManager {
        ...ContestantBuddyInfo
      }
      isBuddyManager
    }
  }
  ${ContestantFragments.buddyInfo}
`;
