export const omitKeys = (obj, keys) => {
  const omittedKeys = keys.reduce((prev, curr) => {
    prev[curr] = true;
    return prev;
  }, {});
  return Object.keys(obj).reduce((prev, curr) => {
    if (!omittedKeys[curr]) {
      prev[curr] = obj[curr];
    }
    return prev;
  }, {});
};
