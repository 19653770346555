import gql from 'graphql-tag';

export const EVENT_SEARCH = gql`
  query EVENT_SEARCH($input: EventSearchInput!) {
    eventSearch(input: $input) {
      id
      rank
      rankLabel
      EventDate
      dateStart
      dateEnd
      dateRegistrationEnd
      EventFormat
      eventType
      eventTypeName
      name
      location
      EventName
      SubmissionStatus
      TimezoneNameIana
      EventRank
      rankLabel
      TimeZoneUID
      TimeZoneName
      TimeZoneOffsetInMinutes
      EventText
      AllowEntry
      EventImage
      Season
      isAlliancePartner
      DisablePartnerSelection
      EventMessaging
      Associations {
        AssociationUID
        Name
        ShortName
        IsAlliancePartner
        AssociationTypeUID
        AssociationTypeAsString
        CollectMembershipNumber
        CollectMembershipFees
        createdAt
        updatedAt
      }
      hasYouthMixedClass
      hasOpenMixedClass
    }
  }
`;
