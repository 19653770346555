import { Record } from 'immutable';

import {
  ENTRY_POOL_GET_BUDDY_GROUP_ENTRIES_SUCCESS,
  ENTRY_POOL_GET_ENTRY_TRANSACTIONS_SUCCESS,
  ENTRY_POOL_ASYNC_START,
  ENTRY_POOL_ASYNC_FAIL,
  ENTRY_POOL_ADD_MANAGER_TO_BUDDY_GROUP,
  ENTRY_POOL_REMOVE_MANAGER_FROM_BUDDY_GROUP,
  ENTRY_POOL_CLEAR_BUDDY_GROUP,
} from 'actions/entryPool';

import { toEntityList } from 'models/BaseList';
import Buddy from 'models/Buddy';

const initialState = new Record({
  loading: false,
  errorMessage: '',
  buddyGroup: toEntityList([], Buddy),
  buddyGroupManager: new Buddy(),
  transactions: [],
})();

const actionsMap = {
  [ENTRY_POOL_ASYNC_START]: (state) => {
    return state.merge({
      loading: true,
      errorMessage: '',
    });
  },
  [ENTRY_POOL_ASYNC_FAIL]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.error,
    });
  },
  [ENTRY_POOL_GET_BUDDY_GROUP_ENTRIES_SUCCESS]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: '',
      buddyGroup: toEntityList(action.data, Buddy),
    });
  },
  [ENTRY_POOL_GET_ENTRY_TRANSACTIONS_SUCCESS]: (state, { data }) => {
    return state.merge({
      loading: false,
      errorMessage: '',
      transactions: data,
    });
  },
  [ENTRY_POOL_ADD_MANAGER_TO_BUDDY_GROUP]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: '',
      buddyGroupManager: action.data,
    });
  },
  [ENTRY_POOL_REMOVE_MANAGER_FROM_BUDDY_GROUP]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
      buddyGroupManager: new Buddy(),
    });
  },
  [ENTRY_POOL_CLEAR_BUDDY_GROUP]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
      buddyGroup: toEntityList([], Buddy),
    });
  },
};

export default function entryPool(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
