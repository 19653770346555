import moment from 'moment';
import { authenticationRefresh } from 'actions/auth';
import { getFromState } from 'helpers';
import { hasFeatureFlag } from '../utils/flagsmith';
import { authenticationRefreshAuth0 } from '../actions/auth';

const intervalInMs = 10000;
const expiryPaddingInMs = intervalInMs + 5000;

const isAuthorized = (auth) => auth && auth.authenticated && auth.authPayload;

const isRefreshExpired = (exp) => {
  return exp ? moment(exp) - moment() < expiryPaddingInMs : false;
};

export default function({ dispatch, getState }) {
  let processHandle;

  return (next) => (action) => {
    if (typeof action !== 'object') return next(action);

    switch (action.type) {
      case 'AUTH_ASYNC_SUCCESS':
      case 'persist/REHYDRATE':
        if (processHandle) {
          clearInterval(processHandle);
        }

        processHandle = setInterval(async () => {
          const authData = getFromState(getState, 'auth');
          const { authPayload, refreshTokenPromise } = authData;
          const { token } = authPayload;
          const auth0 = hasFeatureFlag('auth0');
          if (isAuthorized(authData) && isRefreshExpired(token.expiresIn)) {
            if (!refreshTokenPromise) {
              if (auth0) {
                await authenticationRefreshAuth0(dispatch, getState);
              } else {
                await authenticationRefresh(dispatch, getState);
              }
            } else {
              await refreshTokenPromise;
            }
          }
        }, intervalInMs);
        break;
      case 'DE_AUTH':
        clearInterval(processHandle);
        break;
    }
    next(action);
  };
}
