import { combineReducers } from 'redux';
import { reducer as FormReducer } from 'redux-form';
import auth from './auth';
import user from './user';
import contestantDiscipline from './contestantDiscipline';
import result from './result';
import leaderboard from './leaderboard';
import event from './event';
import associationMembership from './associationMembership';
import payment from './payment';
import userNominations from './userNominations';
import entryChargeRequest from './entryChargeRequest';
import adminSetting from './adminSetting';
import segments from './segments';
import registration from './registration';
import youthAthlete from './youthAthlete';
import entryPool from './entryPool';
import disciplineSegments from './disciplineSegments';
import disciplineClass from './disciplineClass';
import giftCardBalances from './giftCardBalances';
import eventType from './eventType';

export default combineReducers({
  adminSetting,
  associationMembership,
  auth,
  disciplineSegments,
  entryChargeRequest,
  entryPool,
  event,
  form: FormReducer,
  leaderboard,
  payment,
  registration,
  result,
  segments,
  user,
  contestantDiscipline,
  userNominations,
  youthAthlete,
  disciplineClass,
  giftCardBalances,
  eventType,
});
