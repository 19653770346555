import * as Sentry from '@sentry/react';
import { USER_ASYNC_SUCCESS } from 'actions/user';

const SentryMiddleware = (store) => (next) => async (action) => {
  try {
    // Don't add thunks as breadcrumbs, functions aren't serializable.
    if (typeof action !== 'function') {
      const size = JSON.stringify(action).length;
      Sentry.addBreadcrumb({
        message: 'Redux Action',
        // We included up to 10 breadcrumbs, so this limit
        // should keep the total under 50KB.
        data:
          size < 5000
            ? action
            : {
                type: action.type,
                roloSentryMiddleware: 'Action too large',
                size,
              },
      });
    }

    if (action.type === USER_ASYNC_SUCCESS) {
      const { ERAUID, id, email } = action.data;

      Sentry.configureScope((scope) => {
        scope.setUser({
          ERAPersonalUID: ERAUID,
          ERAUserID: id,
          email,
        });
      });
    }
    return await next(action);
  } finally {
    // Whenever a state update occurs, attach the most recent version of the state
    // so if an error does occur it includes the most recent version of the store
    // (even if the error occurred outside of an action).
    const reduxState = { ...store.getState() };
    Sentry.setExtra('redux-state', reduxState);
  }
};

export default SentryMiddleware;
