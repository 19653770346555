import { getTheme, arrayToObject } from 'helpers';
import leaderboardIcon from 'assets/img/icons/leaderboard@4x.png';
import resultsIcon from 'assets/img/icons/results@4x.png';
import nominateIcon from 'assets/img/icons/nominate@4x.png';
import dashboardIcon from 'assets/img/icons/dashboard@4x.png';
import enterIcon from 'assets/img/icons/enter@3x.png';
import enterRETIcon from 'assets/img/ret/ret-enter-icon.svg';
import dashboardRETIcon from 'assets/img/ret/ret-dashboard-icon.svg';
import { hasFeatureFlag } from 'utils/flagsmith';

export const MANAGE_YOUTH_ATHLETES_EDIT = '/manage-youth-athletes/edit';
export const MANAGE_YOUTH_ATHLETES_ADD = '/manage-youth-athletes/add';
export const MANAGE_YOUTH_ATHLETES_VIEW = '/manage-youth-athletes/view';
export const MANAGE_YOUTH_ATHLETES = '/manage-youth-athletes';
export const SETTINGS_PASSWORD = '/settings/password';
export const SETTINGS_CARD = '/settings/card';
export const SETTINGS_EMAIL = '/settings/email';
export const SETTINGS = '/settings';
export const PROFILE_EDIT = '/profile/edit';
export const PROFILE = '/profile';
export const USER_AGREEMENT = '/user-agreement';
export const TERMS_OF_USE = '/terms-of-use';
export const PRIVACY_POLICY = '/privacy-policy';
export const DASHBOARD_COMPLETE = '/dashboard-complete';
export const DASHBOARD = '/dashboard';
export const EVENT_ENTRY_COLLECT_MEMBERSHIP_NUMBER = '/event/entry/membership';
export const EVENT_ENTRY_COLLECT_MEMBERSHIP_FEES =
  '/event/entry/membership-fees';
export const EVENT_ENTRY_UPCOMING_EVENTS = '/event/my-entries';
export const EVENT_ENTRY_UPCOMING_EVENT_ENTRIES = '/event/my-entries/:EventUID';
export const EVENT_ENTRY_PREFERENCES = '/event/entry/preferences';
export const EVENT_ENTRY_REVIEW = '/event/entry/review';
export const EVENT_ENTRY_SUCCESS = '/event/entry/success';
export const EVENT_ENTRY_CHECKOUT = '/event/entry/checkout';
export const EVENT_ENTRY_LEGAL_INFO = '/event/entry/legal-info';
export const EVENT_ENTRY_YOUTH_ATHLETE_INFO = '/event/entry/youth-athlete-info';
export const EVENT_ENTRY = '/event/entry';
export const EVENT_ENTRY_SEARCH = '/event/search';
export const NOMINATE_CHECKOUT = '/nominate/checkout';
export const NOMINATE_SUCCESS = '/nominate/success';
export const NOMINATE_UPCOMING_EVENTS = '/nominate/upcoming';
export const NOMINATE_SEARCH = '/nominate/search';
export const NOMINATE_REVIEW = '/nominate/review';
export const NOMINATE_PERFORMANCES = '/nominate/performances';
export const NOMINATE_POINTS = '/nominate/points';
export const NOMINATE = '/nominate';
export const NOMINATE_CLASS = '/nominate/classess';
export const RESULTS =
  '/results/:userId/segment/:segment/type/:type/discipline/:discipline/:ERAUID/:isYouth';
export const MY_RESULTS = '/my-results';
export const LEADERBOARD = '/leaderboard';
export const RESET_PASSWORD = '/reset-password';
export const FORGOT_PASSWORD = '/forgot-password';
export const ADD_YOUTH_ATHLETE = '/add-youth-athlete';
export const REGISTRATION_YOUTH_ATHLETE = '/registration-youth-athlete';
export const REGISTRATION = '/registration';
export const LOGIN = '/login';
export const AUTH0 = '/auth0';
export const LOGINWITH = '/loginwith';
export const INDEX = '/';
export const GIFTCARD_LIST = '/gift-card/gift-card-list';
export const GIFTCARD_LOAD_BALANCE = '/gift-card/load-balance';

export const HOME =
  getTheme() === 'wcra' ? NOMINATE_SEARCH : EVENT_ENTRY_SEARCH;
export const DOWNLOAD_GIFT_CARD = '/gift-card/download';
export const GIFT_CARD_PREVIEW = '/gift-card/download/preview';

export const routeCodes = {
  BASE_ROUTES: {
    INDEX,
    LOGIN,
    AUTH0,
    LOGINWITH,
    REGISTRATION,
    REGISTRATION_YOUTH_ATHLETE,
    ADD_YOUTH_ATHLETE,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
    DASHBOARD,
    DASHBOARD_COMPLETE,
    PRIVACY_POLICY,
    TERMS_OF_USE,
    USER_AGREEMENT,
    PROFILE,
    PROFILE_EDIT,
    SETTINGS,
    SETTINGS_EMAIL,
    SETTINGS_CARD,
    SETTINGS_PASSWORD,
    MANAGE_YOUTH_ATHLETES,
    MANAGE_YOUTH_ATHLETES_VIEW,
    MANAGE_YOUTH_ATHLETES_ADD,
    MANAGE_YOUTH_ATHLETES_EDIT,
  },
  LEADERBOARD_ROUTES: {
    LEADERBOARD,
    MY_RESULTS,
    RESULTS,
  },
  NOMINATION_ROUTES: {
    NOMINATE,
    NOMINATE_SEARCH,
    NOMINATE_UPCOMING_EVENTS,
    NOMINATE_SUCCESS,
    NOMINATE_CHECKOUT,
    NOMINATE_PERFORMANCES,
    NOMINATE_REVIEW,
    NOMINATE_POINTS,
    NOMINATE_CLASS,
  },
  ENTRY_ROUTES: {
    EVENT_ENTRY_COLLECT_MEMBERSHIP_NUMBER,
    EVENT_ENTRY_COLLECT_MEMBERSHIP_FEES,
    EVENT_ENTRY_SEARCH,
    EVENT_ENTRY,
    EVENT_ENTRY_LEGAL_INFO,
    EVENT_ENTRY_YOUTH_ATHLETE_INFO,
    EVENT_ENTRY_CHECKOUT,
    EVENT_ENTRY_SUCCESS,
    EVENT_ENTRY_UPCOMING_EVENTS,
    EVENT_ENTRY_UPCOMING_EVENT_ENTRIES,
    EVENT_ENTRY_PREFERENCES,
    EVENT_ENTRY_REVIEW,
  },
  GIFT_CARD_ROUTES: {
    DOWNLOAD_GIFT_CARD,
    GIFT_CARD_PREVIEW,
    GIFTCARD_LIST,
    GIFTCARD_LOAD_BALANCE,
  },
};

export const routeTypes = {
  AUTHENTICATED: 'Authenticated',
  UNAUTHENTICATED: 'Unauthenticated',
  PUBLIC: 'Public',
};

function getRoutes() {
  const theme = getTheme();
  const {
    BASE_ROUTES,
    ENTRY_ROUTES,
    NOMINATION_ROUTES,
    LEADERBOARD_ROUTES,
    GIFT_CARD_ROUTES,
  } = routeCodes;

  const baseRoutes = Object.values(BASE_ROUTES);
  const entryRoutes = Object.values(ENTRY_ROUTES);
  const nominationRoutes = Object.values(NOMINATION_ROUTES);
  const leaderboardRoutes = Object.values(LEADERBOARD_ROUTES);
  const giftCardRoutes = Object.values(GIFT_CARD_ROUTES);
  const hideEnter = hasFeatureFlag('hide_enter_vrq');
  const includeEntryRoutes = hideEnter ? [] : entryRoutes;

  switch (theme) {
    case 'ret': {
      return [].concat(baseRoutes, entryRoutes);
    }
    default: {
      return [].concat(
        baseRoutes,
        nominationRoutes,
        includeEntryRoutes,
        leaderboardRoutes,
        giftCardRoutes,
      );
    }
  }
}

const menuItemFactory = (route) => {
  const theme = getTheme();
  const map = {
    [EVENT_ENTRY_SEARCH]: {
      itemUrl: EVENT_ENTRY_SEARCH,
      itemImgSrc: theme === 'ret' ? enterRETIcon : enterIcon,
      itemClass: 'enter-icon',
      itemTitle: 'Enter',
      itemCount: true,
    },
    [LEADERBOARD]: {
      itemUrl: LEADERBOARD,
      itemImgSrc: leaderboardIcon,
      itemClass: 'leaderboard-icon',
      itemTitle: 'Leaders',
    },
    [MY_RESULTS]: {
      itemUrl: MY_RESULTS,
      itemImgSrc: resultsIcon,
      itemClass: 'results-icon',
      itemTitle: 'Results',
    },
    [NOMINATE_UPCOMING_EVENTS]: {
      itemUrl: NOMINATE_UPCOMING_EVENTS,
      itemImgSrc: nominateIcon,
      itemClass: 'nominate-icon',
      itemTitle: 'Nominate',
    },
    [DASHBOARD]: {
      itemUrl: DASHBOARD,
      itemImgSrc: theme === 'ret' ? dashboardRETIcon : dashboardIcon,
      itemClass: 'dashboard-icon',
      itemTitle: 'Dashboard',
    },
  };
  return map[route];
};

function getMenuRoutes() {
  const theme = getTheme();
  const hideEnter = hasFeatureFlag('hide_enter_vrq');
  const includeEntryRoutes = hideEnter ? [] : EVENT_ENTRY_SEARCH;
  switch (theme) {
    case 'ret': {
      return [].concat(EVENT_ENTRY_SEARCH, DASHBOARD).map(menuItemFactory);
    }
    default: {
      return []
        .concat(
          LEADERBOARD,
          includeEntryRoutes,
          MY_RESULTS,
          NOMINATE_UPCOMING_EVENTS,
          DASHBOARD,
        )
        .map(menuItemFactory);
    }
  }
}

export const routesMap = () => arrayToObject(getRoutes());

export const menuRoutes = getMenuRoutes;
