import Event from 'models/Event';
import { Record } from 'immutable';
import { toEntityList } from 'models/BaseList';

import {
  EVENT_ACTION_SUCCESS,
  EVENT_ACTION_CLEAR,
  EVENT_ASYNC_START,
  EVENT_ASYNC_STOP,
  EVENT_ASYNC_FAIL,
  EVENT_ASYNC_SUCCESS,
  EVENT_LIST_ASYNC_SUCCESS,
  EVENT_ASYNC_CLEAR_LIST,
  EVENT_SHOW_MESSAGING_BANNER,
  EVENT_RANK_ACTION_SUCCESS,
  EVENT_IS_YOUTH_NOMINATION_ACTION_SUCCESS,
} from 'actions/event';

const initialState = new Record({
  loading: false,
  errorMessage: '',
  data: new Event(),
  list: toEntityList([], Event),
  showEventMessagingBanner: false,
})();

const actionsMap = {
  [EVENT_ACTION_SUCCESS]: (state, action) => {
    return state.merge({
      loading: false,
      data: new Event(action.data),
    });
  },
  [EVENT_ACTION_CLEAR]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
      data: {},
    });
  },
  [EVENT_ASYNC_START]: (state) => {
    return state.merge({
      loading: true,
      errorMessage: '',
    });
  },
  [EVENT_ASYNC_STOP]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
    });
  },
  [EVENT_ASYNC_SUCCESS]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: null,
      data: new Event(action.data),
    });
  },

  [EVENT_RANK_ACTION_SUCCESS]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: null,
      data: new Event({ ...action.data.event, EventRank: action.data.rank }),
    });
  },
  [EVENT_IS_YOUTH_NOMINATION_ACTION_SUCCESS]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: null,
      data: new Event({
        ...action.data.event,
        selectedYouthNomination: action.data.selectedYouthNomination,
      }),
    });
  },
  [EVENT_LIST_ASYNC_SUCCESS]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: null,
      list: toEntityList(action.data, Event),
    });
  },
  [EVENT_ASYNC_CLEAR_LIST]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: null,
      list: toEntityList([], Event),
    });
  },
  [EVENT_ASYNC_FAIL]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.error,
    });
  },
  [EVENT_SHOW_MESSAGING_BANNER]: (state, action) => {
    return state.merge({
      showEventMessagingBanner: action.value,
    });
  },
};

export default function event(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
