import gql from 'graphql-tag';

export const AUTHENTICATE_USER = gql`
  mutation Authenticate($input: LoginInputType!) {
    loginUser(input: $input) {
      user {
        id
        DOB
        Gender
        email
        ERAUID
        lastName
        firstName
        disciplineId
        phone
        street
        street2
        city
        state
        zip
        country
        socialTags
        nickname
        hometown
        careerEarnings
        worldChampionshipEarned
        height
        weight
        facebook
        twitter
        instagram
        LegalFirstName
        LegalLastName
        SSN
        USCitizen
        isParent
        isAthlete
        youthAthletes {
          ERAUID
          FullName
          firstName
          lastName
          Disc
          DOB
          Gender
          entryData {
            confirmedEntries {
              EventUID
              entries {
                EPUID
              }
            }
            pendingEntries {
              EventUID
              entries {
                EPUID
              }
            }
            pendingWithMembershipEntries {
              EventUID
              entries {
                EPUID
              }
            }
          }
        }
        entryData {
          confirmedEntries {
            EventUID
            entries {
              EPUID
            }
          }
          pendingEntries {
            EventUID
            entries {
              EPUID
            }
          }
          pendingWithMembershipEntries {
            EventUID
            entries {
              EPUID
            }
          }
        }
      }
      token {
        expiresIn
        tokenType
        accessToken
        refreshToken
      }
      error {
        message
      }
    }
  }
`;
