// @flow
import { Record } from 'immutable';

export type ContestantDisciplineType = {
  DisciplineUID: number,
  Name: string,
  CompetitionLevel: string,
  ShortCompetitionLevel: string,
  SegmentUID: number,
};

const defaultValues = {
  DisciplineUID: 0,
  Name: '',
  CompetitionLevel: '',
  ShortCompetitionLevel: '',
  SegmentUID: 0,
};

export default class ContestantDiscipline extends Record(
  defaultValues,
  'ContestantDiscipline',
) {
  constructor(values: ?ContestantDisciplineType) {
    super({
      ...values,
    });
  }
}
