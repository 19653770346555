export const formatSSNDisplayingLastFourDigitsOnly = (value) => {
  return (
    value &&
    [...value]
      .map((char, index) => {
        if (index >= 5) {
          return char;
        }

        const charOrAsterisk = index + 1 < value.length ? '*' : char;

        if (index === 2 || index === 4) {
          return charOrAsterisk + '-';
        }

        return charOrAsterisk;
      })
      .join('')
  );
};

export const formatSSN = (value) => {
  return (
    value &&
    [...value]
      .map((char, index) => {
        const charOrAsterisk = index + 1 < value.length ? '*' : char;

        if (index === 2 || index === 4) {
          return charOrAsterisk + '-';
        }

        return charOrAsterisk;
      })
      .join('')
  );
};
