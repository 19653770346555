import gql from 'graphql-tag';

export const EVENT_GET_ENTRY_COUNTS = gql`
  query EVENT_GET_ENTRY_COUNTS($id: ID!, $ERAUID: ID) {
    eventGet(id: $id, ERAUID: $ERAUID) {
      entryCounts {
        ERAUID
        disciplines {
          compType
          entryCount
          eventEntryDisciplineFeeUID
        }
      }
    }
  }
`;
