import { isYouthEvent } from './isYouthEvent';

export const getEventSegmentFilter = (activeDiscipline, event) => {
  let eventSegmentFilter = event;
  if (activeDiscipline.Division) {
    if (isYouthEvent(activeDiscipline.Division)) {
      eventSegmentFilter = {
        ...event,
        EventRank: activeDiscipline.Division,
      };
    }
  }
  return eventSegmentFilter;
};
