const currentDate = new Date();
const currentYear = currentDate.getFullYear();

export const maxBirthDateYear = currentYear - 2;
export const minBirthDateYear = currentYear - 150;

export const isInvalidBirthdate = (dateOfBirth) =>
  !dateOfBirth ||
  !dateOfBirth.isValid() ||
  dateOfBirth.year() > maxBirthDateYear ||
  dateOfBirth.year() < minBirthDateYear;
