import { Record } from 'immutable';

const defaultValues = {
  leaders: null,
};

export default class Leaderboard extends Record(defaultValues, Leaderboard) {
  constructor(values) {
    super({
      ...values,
    });
  }
}
