import gql from 'graphql-tag';

export const ContestantFragments = {
  all: gql`
    fragment ContestantAll on Contestant {
      id
      ERAUID
      FullName
      FName
      LName
      Nickname
      Suffix
      CType
      StatusType
      Penalty
      BioUID
      AwardUID
      HeritageUID
      HistoryUID
      InjuryCurrentUID
      InjuryHistoryUID
      QuoteUID
      PhotoUID
      Identifier
      ContractorUID
      Brand
      Hometown
      Website
      Facebook
      Twitter
      Instagram
      YouTube
      Pinterest
      RookieYear
      Height
      Weight
      createdAt
      LastUpdate
      Snapchat
      LinkedIn
      MemType
      PurchaseDate
      userId
      Disc
      CareerEarnings
      WorldChampionshipEarned
      PayeeNumber
      PhotoFilename
      email
      firstName
      lastName
      socialTags
      role
      status
      DOB
      Phone
      LegalFirstName
      LegalLastName
      SSN
      street
      city
      state
      zip
      country
      street2
      USCitizen
      Gender
      numberOfResults
      nominations
      youthList
      isParent
      isAthlete
      isYouth
      type
      disciplineAsString
      isUnregisteredAthlete
      associationMembers {
        Name
        MemberId
      }
    }
  `,
  buddyInfo: gql`
    fragment ContestantBuddyInfo on Contestant {
      ERAUID
      FullName
      associationMembers {
        Name
        MemberId
      }
    }
  `,
};
