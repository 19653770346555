import gql from 'graphql-tag';

export const EDIT_USER_EMAIL_AUTH0 = gql`
  mutation editUserEmailAuth0($input: EditUserInput!) {
    editUserEmailAuth0(input: $input) {
      success
      error
      user {
        id
        ERAUID
        firstName
        lastName
        email
        DOB
        Gender
        disciplineId
        phone
        street
        street2
        city
        state
        zip
        country
        socialTags
        photoFileName
        LegalFirstName
        LegalLastName
        nickname
        hometown
        careerEarnings
        worldChampionshipEarned
        height
        weight
        facebook
        instagram
        twitter
        isParent
        isAthlete
        USCitizen
        TaxInfoUpdateDate
      }
      token {
        tokenType
        accessToken
        refreshToken
        expiresIn
      }
    }
  }
`;
