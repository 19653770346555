const TIME_ZONE_UIDS = [
  {
    timeZoneUID: 1,
    timeZone: 'EST',
  },
  {
    timeZoneUID: 3,
    timeZone: 'CST',
  },
  {
    timeZoneUID: 4,
    timeZone: 'PST',
  },
  {
    timeZoneUID: 5,
    timeZone: 'AWST',
  },
  {
    timeZoneUID: 6,
    timeZone: 'ACWST',
  },
  {
    timeZoneUID: 7,
    timeZone: 'ACST',
  },
  {
    timeZoneUID: 8,
    timeZone: 'AEST',
  },
  {
    timeZoneUID: 11,
    timeZone: 'LHST',
  },
  {
    timeZoneUID: 12,
    timeZone: 'MST',
  },
  {
    timeZoneUID: 13,
    timeZone: 'CEST',
  },
  {
    timeZoneUID: 14,
    timeZone: 'AST',
  },
  {
    timeZoneUID: 15,
    timeZone: 'HST',
  },
];

export default TIME_ZONE_UIDS;
