import moment from 'moment';
export const areSegmentDatesDifferentThanEventDates = (
  disciplineSegments,
  event,
  filteredSegments,
) => {
  let eventSegments = [];
  Object.keys(disciplineSegments).map((disciplineKey) => {
    eventSegments = filteredSegments.filter((segment) =>
      disciplineSegments[disciplineKey].includes(segment.value),
    );
  });
  return eventSegments
    .map(
      (eventSegment) =>
        moment(event.dateStart).isBefore(eventSegment.StartDate) ||
        moment(event.dateEnd).isAfter(eventSegment.EndDate),
    )
    .every((segment) => segment);
};
