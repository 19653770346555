import { sumBy } from 'lodash';

export const getPartnerEntryDiscFee = (
  entryFees = [],
  disciplineId = '',
  customFee = false,
) => {
  const disciplines = entryFees.filter(
    (entry) => entry.disciplineId === disciplineId,
  );

  if (!disciplines.length) return 0;

  return sumBy(disciplines, (discipline) =>
    customFee ? discipline.partnerCustomFee : discipline.partnerEntryFee,
  );
};
