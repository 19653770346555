import React from 'react';
import PropTypes from 'prop-types';

const App = ({ children }) => {
  try {
    return <div className="layout-wrapper">{children}</div>;
  } catch (e) {
    window.location.reload(true);
    return null;
  }
};

App.propTypes = {
  children: PropTypes.object.isRequired,
};

export default App;
