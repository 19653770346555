// @flow
/* global APP_CONFIG */

// $FlowIgnore
const base = APP_CONFIG.api.url;
const defaultContentType = 'application/json';

type DefaultHeaders = {
  'Content-Type'?: ?string,
  Authorization?: string,
};

export default async (payload: Object) => {
  const { path, method, body, cType, header, accessToken = null } = payload;
  const url = `${base}/${path}`;
  const contentType = cType || defaultContentType;
  const bodyJson =
    contentType === 'application/json' ? JSON.stringify(body) : body;

  const defaultHeaders: DefaultHeaders = {
    'Content-Type': `${contentType}`,
  };

  if (accessToken) defaultHeaders.Authorization = `Bearer ${accessToken}`;
  if (contentType === 'multipart/form-data')
    delete defaultHeaders['Content-Type'];

  const headers = { ...defaultHeaders, ...header };

  const response = await fetch(url, { method, body: bodyJson, headers });
  const responseBody = await response
    .json()
    .then((res) => res)
    .catch((e) => {
      if (e instanceof SyntaxError) {
        window.location.reload(true);
      }
      return e;
    });

  // @TODO: Set this to the returned response error message
  if (response.status >= 400) {
    throw Error(responseBody.message);
  }

  return responseBody;
};
