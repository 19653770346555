// @flow
import { Record } from 'immutable';
import { NO_MEMBER_STATUS } from 'constants/associationMembership';
import { getCollectMembershipAssociations } from 'helpers';
export type MembershipFee = {
  AssociationUID: number,
  fee: number,
};

export type AssociationTypeFee = {
  AssociationTypeFeeUID: number,
  AssociationMemberTypeUID: number,
  AssociationShortName: string,
  AssociationUID: number,
  FeeAmount: number,
  IsNonMember: boolean,
  IsRenewalFee: boolean,
  IsPermitFee: boolean,
  MembershipType: string,
};

export type AthleteMembershipData = {
  typeFeeUID: number,
  associationUID: number,
  membershipNumber: string,
};

export type AssociationMembershipType = {
  membershipFees: MembershipFee[],
  selectedMembershipStatus: string,
  selectedMemberAssociationUIDs: Array<number>,
  athleteMembershipData: Array<AthleteMembershipData>,
};

const defaultValues = {
  membershipFees: [],
  selectedMembershipStatus: NO_MEMBER_STATUS,
  selectedMemberAssociationUIDs: [],
  athleteMembershipData: [],
};

export default class AssociationMembership extends Record(
  defaultValues,
  AssociationMembership,
) {
  constructor(values: AssociationMembershipType) {
    super({
      ...values,
    });
  }
}

export type AthleteAssociationMembershipDataType = {
  membershipNumber: string,
  associationShortName: string,
};

export const getAthleteAssociationMembershipNumbers = (
  athleteMembershipData: Array<AthleteMembershipData>,
  associations: Object,
  primaryAssociationUID: number,
) => {
  // Needed to ensure we keep the primary association uid order
  // and not the order in which the user entered the associations numbers
  const sortedAssociations = getCollectMembershipAssociations(
    primaryAssociationUID,
    associations,
  );
  const associationMembershipNumbers = [];
  sortedAssociations.forEach((sa) => {
    const amd = athleteMembershipData.find(
      (amd) => amd.associationUID === sa.AssociationUID,
    );
    if (amd && (amd.membershipNumber || amd.membershipNumber === '')) {
      associationMembershipNumbers.push({
        membershipNumber: amd.membershipNumber,
        associationShortName: sa.ShortName,
      });
    }
  });
  return associationMembershipNumbers;
};
