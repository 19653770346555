// @flow
import { Record } from 'immutable';
export type SegmentType = {
  SegmentUID: ?number,
  Name: ?string,
  StartDate: ?string,
  EndDate: ?string,
  ShortName: ?string,
  NominatableByYouth: ?boolean,
  HasOpen: ?boolean,
  HasYouth: ?boolean,
  WomensOnly: ?boolean,
};

const defaultValues: SegmentType = {
  SegmentUID: null,
  Name: '',
  StartDate: null,
  EndDate: null,
  ShortName: '',
  NominatableByYouth: true,
  HasOpen: null,
  HasYouth: null,
  WomensOnly: null,
};

export default class Segment extends Record(defaultValues, Segment) {
  constructor(values: ?SegmentType) {
    super({
      ...values,
    });
  }
}
