import React from 'react';
import FormField from 'components/FormField';

export const renderField = ({
  input,
  name,
  placeholder,
  className,
  type,
  meta: { touched, error },
}) => (
  <FormField
    input={input}
    placeholder={placeholder}
    className={className}
    type={type}
    name={name}
    touched={touched}
    error={error}
  />
);
