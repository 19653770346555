export const getPreferencesAndNotsData = (
  feesArray = [],
  discipline = null,
  hasClasses = false,
) => {
  if (!feesArray.length) return [];

  let preferences = [];
  let nots = [];
  const preferenceLabel = 'PREF #';
  const notLabel = 'NOT #';
  let currentPreferenceAndNot = null;

  let fees = null;
  if (!hasClasses) {
    fees = feesArray.find(
      (disciplineFee) => disciplineFee.disciplineId === discipline.value,
    );
  } else {
    fees = feesArray.find(
      (classFee) => classFee.ClassName === discipline.ClassName,
    );
  }

  if (!fees) return [];

  const { entryPreferenceData: entryPreferenceAndNotsData } = fees;

  if (!entryPreferenceAndNotsData) {
    return [];
  }

  for (const key in entryPreferenceAndNotsData) {
    if (entryPreferenceAndNotsData[key] && discipline.performances) {
      currentPreferenceAndNot = discipline.performances.find(
        (performance) =>
          performance.PerformanceUID === entryPreferenceAndNotsData[key],
      );
      if (currentPreferenceAndNot && key.includes('Pref')) {
        preferences.push({
          ...currentPreferenceAndNot,
          label: `${preferenceLabel}${key.charAt(key.length - 1)}`,
        });
      }

      if (currentPreferenceAndNot && key.includes('Out')) {
        nots.push({
          ...currentPreferenceAndNot,
          label: `${notLabel}${key.charAt(key.length - 1)}`,
        });
      }
    }
  }

  if (nots.length === 1) nots[0].label = 'NOT';

  return preferences.concat(nots);
};
