// @flow
import React from 'react';
import { AlertCircle, CheckCircle } from 'react-feather';
import { DefaultToast } from 'react-toast-notifications';

type ToastProps = {|
  children: string,
  appearance: string,
  autoDismiss: boolean,
  autoDismissTimeout: number,
  placement: string,
  transitionDuration: number,
|};

const iconStyle = {
  marginRight: '10px',
  width: '17px',
  height: '17px',
};

const Toast = ({
  children,
  appearance = 'success',
  autoDismiss = true,
  autoDismissTimeout = 5000,
  placement = 'top-center',
  transitionDuration = 220,
  ...props
}: ToastProps) => {
  const renderIcon = () => {
    switch (appearance) {
      default:
      case 'success':
        return <CheckCircle style={iconStyle} />;
      case 'error':
        return <AlertCircle style={iconStyle} />;
    }
  };

  return (
    <DefaultToast
      appearance={appearance}
      autoDismiss={autoDismiss}
      autoDismissTimeout={autoDismissTimeout}
      placement={placement}
      transitionDuration={transitionDuration}
      {...props}
    >
      {renderIcon()}
      {children}
    </DefaultToast>
  );
};

export default Toast;
