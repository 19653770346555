import { sumBy } from 'lodash';

export const getLengthOfNominations = (disciplineFees) => {
  let nominationsLength = 0;

  disciplineFees.forEach(({ nominationSegments }) => {
    nominationsLength += sumBy(nominationSegments, 'count');
  });
  return nominationsLength;
};
