import api from 'api';
import get from 'lodash/get';
import client from 'api/apollo';
import { EVENT_GET } from 'queries/EventGet';
import { EVENT_SEARCH } from 'queries/EventSearch';
import { GET_CONTESTANT_BUDDIES_FOR_EVENT } from 'queries/GetContestantBuddiesForEvent';
import { setMembershipFees } from 'actions/associationMembership';
import {
  getFromState,
  getAccessToken,
  logError,
  getNestedProperty,
} from 'helpers';
import { hasFeatureFlag } from 'utils/flagsmith';
import {
  EVENT_DIVISIONS_TYPE,
  OPEN_DIVISIONS,
  YOUTH_DIVISIONS,
} from 'constants/event';
import { teamDisciplineCouple } from 'constants/disciplines';

export const EVENT_ACTION_SUCCESS = 'EVENT_ACTION_SUCCESS';
export const EVENT_ACTION_CLEAR = 'EVENT_ACTION_CLEAR';
export const EVENT_ASYNC_START = 'EVENT_ASYNC_START';
export const EVENT_ASYNC_STOP = 'EVENT_ASYNC_STOP';
export const EVENT_ASYNC_FAIL = 'EVENT_ASYNC_FAIL';
export const EVENT_ASYNC_SUCCESS = 'EVENT_ASYNC_SUCCESS';
export const EVENT_LIST_ASYNC_SUCCESS = 'EVENT_LIST_ASYNC_SUCCESS';
export const EVENT_ASYNC_CLEAR_LIST = 'EVENT_ASYNC_CLEAR_LIST';
export const EVENT_SHOW_MESSAGING_BANNER = 'EVENT_SHOW_MESSAGING_BANNER';

export const EVENT_RANK_ACTION_SUCCESS = 'EVENT_RANK_ACTION_SUCCESS';
export const EVENT_RANK_ASYNC_START = 'EVENT_RANK_ASYNC_START';

export const EVENT_IS_YOUTH_NOMINATION_ACTION_SUCCESS =
  'EVENT_IS_YOUTH_NOMINATION_ACTION_SUCCESS';
export const EVENT_IS_YOUTH_NOMINATION_ASYNC_START =
  'EVENT_IS_YOUTH_NOMINATION_ASYNC_START';

export function eventActionSuccess(data) {
  return {
    type: EVENT_ACTION_SUCCESS,
    data,
  };
}

function eventActionClear(data) {
  return {
    type: EVENT_ACTION_CLEAR,
    data,
  };
}

function eventAsyncStart() {
  return {
    type: EVENT_ASYNC_START,
  };
}

function eventRankAsyncStart() {
  return {
    type: EVENT_RANK_ASYNC_START,
  };
}

function setEventRankSuccess(event, rank) {
  return {
    type: EVENT_RANK_ACTION_SUCCESS,
    data: { event, rank },
  };
}

function eventIsYouthNominationAsyncStart() {
  return {
    type: EVENT_RANK_ASYNC_START,
  };
}

function setEventIsYouthNominationSuccess(event, selectedYouthNomination) {
  return {
    type: EVENT_IS_YOUTH_NOMINATION_ACTION_SUCCESS,
    data: { event, selectedYouthNomination },
  };
}

function eventAsyncStop() {
  return {
    type: EVENT_ASYNC_STOP,
  };
}

function eventListAsyncSuccess(data) {
  return {
    type: EVENT_LIST_ASYNC_SUCCESS,
    data,
  };
}

function eventAsyncFail(error) {
  return {
    type: EVENT_ASYNC_FAIL,
    error,
  };
}

export function eventClearList() {
  return {
    type: EVENT_ASYNC_CLEAR_LIST,
  };
}

export function selectEvent(id, ERAUID) {
  return async (dispatch) => {
    dispatch(eventAsyncStart());
    try {
      const eventPayload = {
        query: EVENT_GET,
        variables: { id, ERAUID },
        fetchPolicy: 'network-only',
      };
      const response = await client.query(eventPayload);
      const event = response.data.eventGet;
      dispatch(setMembershipFees(get(event, 'Associations', [])));
      if (event.id === null) {
        throw new Error('Entry not found');
      }

      dispatch(eventActionSuccess(event));

      return event;
    } catch (error) {
      logError(error);
      const err = 'There was a problem getting your event, please try again.';
      dispatch(eventAsyncFail(err));

      return null;
    }
  };
}

export function selectedPreferencesPerEvent(EventUID, disciplineId) {
  return async (dispatch, getState) => {
    const { authPayload } = getFromState(getState, 'auth');
    const accessToken = getAccessToken(authPayload);

    dispatch(eventAsyncStart());
    try {
      const response = await api({
        path: `event/${EventUID}/count-entries-per-performance-discipline?CompType=${disciplineId}`,
        method: 'GET',
        accessToken,
      });

      const eventData = getFromState(getState, 'event');

      const event = { ...eventData, entriesSelectedPreferences: response };

      dispatch(eventActionSuccess(event));

      return response;
    } catch (error) {
      logError(error);
      const err =
        'There was a problem getting current preferences for the event, please try again.';
      dispatch(eventAsyncFail(err));

      return null;
    }
  };
}

export function setEventRank(rank) {
  return async (dispatch, getState) => {
    const event = getFromState(getState, 'event');

    dispatch(eventRankAsyncStart());
    try {
      dispatch(setEventRankSuccess(event, rank));
    } catch (error) {
      return null;
    }
  };
}

export function selectEventYouthNomination(selectedYouthNomination) {
  return async (dispatch, getState) => {
    const event = getFromState(getState, 'event');

    dispatch(eventIsYouthNominationAsyncStart());
    try {
      dispatch(
        setEventIsYouthNominationSuccess(event, selectedYouthNomination),
      );
    } catch (error) {
      return null;
    }
  };
}

export function getEventSearchResults(
  values,
  searchType,
  ERAUID = null,
  showOpenEventsOnly,
) {
  return async (dispatch) => {
    dispatch(eventAsyncStart());
    const filtersV2 = hasFeatureFlag('vrq-filters-v2');
    try {
      const {
        Division = [],
        Discipline,
        Alliance = [],
        CompetitionLevel,
        searchTerm = '',
        ...rest
      } = values;
      let input = filtersV2
        ? {
            ...rest,
            Alliance,
            Discipline,
            searchTerm,
            Division,
            searchType,
            ERAUID,
            showOpenEventsOnly,
          }
        : {
            searchTerm,
            searchType,
            ERAUID,
          };
      if (filtersV2) {
        const {
          YOUTH_EVENT_TYPE,
          OPEN_EVENT_TYPE,
          OPEN_YOUTH_EVENT_TYPE,
        } = EVENT_DIVISIONS_TYPE;
        let newDivision = [];
        let newAlliance = [];
        let newDiscipline = [];
        if (Division.length === 0) {
          const divisionForCompetitionLevel = {
            [OPEN_EVENT_TYPE]: OPEN_DIVISIONS,
            [YOUTH_EVENT_TYPE]: YOUTH_DIVISIONS,
            [OPEN_YOUTH_EVENT_TYPE]: [...OPEN_DIVISIONS, ...YOUTH_DIVISIONS],
          };
          newDivision = divisionForCompetitionLevel[CompetitionLevel];
        } else {
          newDivision = Division.map((division) => division.value);
        }
        if (Discipline) {
          newDiscipline = !teamDisciplineCouple[Discipline]
            ? [Discipline]
            : [...teamDisciplineCouple[Discipline]];
        }
        newAlliance = Alliance.map((alliance) => alliance.value);
        input = {
          ...rest,
          searchType,
          searchTerm,
          ERAUID,
          showOpenEventsOnly,
          Division: newDivision,
          Alliance: newAlliance,
          Discipline: newDiscipline,
        };
      }
      const eventPayload = {
        query: EVENT_SEARCH,
        variables: { input },
        fetchPolicy: 'network-only',
      };
      const response = await client.query(eventPayload);
      const events = response.data.eventSearch;
      dispatch(eventListAsyncSuccess(events));

      return events;
    } catch (error) {
      logError(error);
      const err = 'Problem fetching event search events.';
      dispatch(eventAsyncFail(err));

      return null;
    }
  };
}

export function clearEvent(event) {
  return (dispatch) => {
    dispatch(eventActionClear(event));
  };
}

export const getEventBuddies = () => {
  return async (dispatch, getState) => {
    dispatch(eventAsyncStart());

    const { id } = getFromState(getState, 'event');
    const user = getFromState(getState, 'user');

    const payload = {
      query: GET_CONTESTANT_BUDDIES_FOR_EVENT,
      variables: { ERAUID: getNestedProperty('ERAUID', user), EventUID: id },
      fetchPolicy: 'network-only',
    };

    try {
      const response = await client.query(payload);
      dispatch(eventAsyncStop());
      return response.data.getContestantBuddiesForEvent;
    } catch (error) {
      logError(error);
      dispatch(
        eventAsyncFail(
          "An error occurred while trying to fetch the event's buddies.",
        ),
      );
      return null;
    }
  };
};

export const setShowEventMessagingBanner = (value) => {
  return {
    type: EVENT_SHOW_MESSAGING_BANNER,
    value,
  };
};
