export const MEMBERSHIP_NUMBER_CHANGE = 'MEMBERSHIP_NUMBER_CHANGE';
export const MEMBERSHIP_STATUS_SELECT = 'MEMBERSHIP_STATUS_SELECT';
export const MEMBERSHIP_TYPE_FEE_SELECT = 'MEMBERSHIP_TYPE_FEE_SELECT';
export const SET_MEMBERSHIP_FEES = 'SET_MEMBERSHIP_FEES';
export const CLEAN_MEMBERSHIP = 'CLEAN_MEMBERSHIP';

export function membershipNumberChange(data) {
  return {
    type: MEMBERSHIP_NUMBER_CHANGE,
    data,
  };
}

export function membershipStatusSelect(data) {
  return {
    type: MEMBERSHIP_STATUS_SELECT,
    data,
  };
}

export function membershipTypeFeeSelect(data) {
  return {
    type: MEMBERSHIP_TYPE_FEE_SELECT,
    data,
  };
}

export function setMembershipFees(data) {
  return {
    type: SET_MEMBERSHIP_FEES,
    data,
  };
}

export function cleanMembership() {
  return {
    type: CLEAN_MEMBERSHIP,
  };
}
