import { pluralize } from 'helpers';

export const getPreferencesText = (prefCount = 0, notCount = 0) => {
  const preferenceText = prefCount
    ? pluralize(`${prefCount} preference`, prefCount !== 1)
    : '';
  const outText = notCount ? pluralize(`${notCount} not`, notCount !== 1) : '';

  if (preferenceText.length && outText.length) {
    return `Preferences: ${preferenceText}, ${outText}`;
  }
  if (preferenceText.length) {
    return `Preferences: ${preferenceText}`;
  }
  if (outText.length) {
    return `Preferences: ${outText}`;
  }
  return '';
};
