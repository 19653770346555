export const EVENT_DIVISIONS = {
  DIVISION_1: 129,
  DIVISION_2: 130,
  DIVISION_3: 131,
  DIVISION_4: 132,
  DIVISION_5: 202,
  DIVISION_YOUTH: 177,
  DIVISION_YOUTH_2: 208,
  DIVISION_YOUTH_3: 209,
  DIVISION_YOUTH_4: 210,
  DIVISION_YOUTH_5: 211,
  MULTIPLE: 215,
};

export const EVENT_DIVISION_NAMES = {
  [EVENT_DIVISIONS.DIVISION_1]: 'Division 1',
  [EVENT_DIVISIONS.DIVISION_2]: 'Division 2',
  [EVENT_DIVISIONS.DIVISION_3]: 'Division 3',
  [EVENT_DIVISIONS.DIVISION_4]: 'Division 4',
  [EVENT_DIVISIONS.DIVISION_5]: 'Division 5',
  [EVENT_DIVISIONS.DIVISION_YOUTH]: 'Division Y1',
  [EVENT_DIVISIONS.DIVISION_YOUTH_2]: 'Division Y2',
  [EVENT_DIVISIONS.DIVISION_YOUTH_3]: 'Division Y3',
  [EVENT_DIVISIONS.DIVISION_YOUTH_4]: 'Division Y4',
  [EVENT_DIVISIONS.DIVISION_YOUTH_5]: 'Division Y5',
  [EVENT_DIVISIONS.MULTIPLE]: 'Multiple',
};

export const EVENT_DIVISION_CSS_CLASSES = {
  129: 1,
  130: 2,
  131: 3,
  132: 4,
  202: 5,
  177: 6,
  208: 7,
  209: 8,
  210: 9,
  211: 10,
  215: 11,
};

export const OPEN_DIVISIONS = [
  EVENT_DIVISIONS.DIVISION_1,
  EVENT_DIVISIONS.DIVISION_2,
  EVENT_DIVISIONS.DIVISION_3,
  EVENT_DIVISIONS.DIVISION_4,
  EVENT_DIVISIONS.DIVISION_5,
];

export const openDivisionsWithNames = OPEN_DIVISIONS.map((key) => {
  return {
    label: EVENT_DIVISION_NAMES[key],
    value: key,
  };
});

export const OPEN_YOUTH_DIVISIONS = [EVENT_DIVISIONS.MULTIPLE];

export const MULTIPLE_DIVISIONS = {
  name: 'DIVISIONS BY CLASS',
  id: EVENT_DIVISIONS.MULTIPLE,
  // sortOrder
  rank: 11,
};

export const YOUTH_DIVISIONS = [
  EVENT_DIVISIONS.DIVISION_YOUTH,
  EVENT_DIVISIONS.DIVISION_YOUTH_2,
  EVENT_DIVISIONS.DIVISION_YOUTH_3,
  EVENT_DIVISIONS.DIVISION_YOUTH_4,
  EVENT_DIVISIONS.DIVISION_YOUTH_5,
];

export const youthDivisionsWithNames = YOUTH_DIVISIONS.map((key) => {
  return {
    label: EVENT_DIVISION_NAMES[key],
    value: key,
  };
});

export const EVENT_DIVISIONS_TYPE = {
  ALL_EVENT_TYPE: 'all',
  OPEN_EVENT_TYPE: 'OPEN ONLY',
  YOUTH_EVENT_TYPE: 'YOUTH ONLY',
  OPEN_YOUTH_EVENT_TYPE: 'OPEN/YOUTH',
};

export const EVENT_FORMAT = {
  STANDARD: 127,
  BRACKET: 128,
  MULTIPLE_ENTRY: 156,
};

// These are the SortNumber on the ERATypeDB
export const YOUTH_RANK = 6;
export const YOUTH_RANK_2 = 7;
export const YOUTH_RANK_3 = 8;
export const YOUTH_RANK_4 = 9;
export const YOUTH_RANK_5 = 10;

export const YOUTH_RANKS = [
  YOUTH_RANK,
  YOUTH_RANK_2,
  YOUTH_RANK_3,
  YOUTH_RANK_4,
  YOUTH_RANK_5,
];

export const isYouthRank = (rank) => {
  return YOUTH_RANKS.includes(rank);
};

// This is the legacy youth segment short name that was used before the `Divisionalize Youth` feature.
// Now we can have different youth segments with different types and competition levels.
export const OLD_YOUTH_SEGMENT_SHORT_NAME = 'DY';

export const MAX_EVENTS_NUMBER_TEXT = '300';

export const BUDDY_GROUP_LIMIT = 7;
