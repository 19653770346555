import Base from 'models/Base';

import {
  PAYMENT_ACTION_SUCCESS,
  PAYMENT_ACTION_CLEAR,
  PAYMENT_ASYNC_START,
  PAYMENT_ASYNC_SUCCESS,
  PAYMENT_ASYNC_FAIL,
  PAYMENT_ACTION_INIT_AMOUNT,
  PAYMENT_ACTION_COUPON_START,
  PAYMENT_ACTION_COUPON_SUCCESS,
  PAYMENT_ACTION_COUPON_FAIL,
  PAYMENT_ACTION_COUPON_CLEAR,
} from 'actions/payment';

const initialState = new Base({
  loading: false,
  errorMessage: '',
  data: {},
});
const getKey = (key) => (state) =>
  typeof state.data.get !== 'undefined' ? state.data.get(key) : '';
const getAmount = getKey('amount');
const getNomination = getKey('nomination');
const getCoupon = getKey('coupon');
const getGiftCardAccountBalances = getKey('giftCardAccountBalances');
const actionsMap = {
  [PAYMENT_ACTION_INIT_AMOUNT]: (state, action) => {
    const { amount, nomination, giftCardAccountBalances } = action.data;
    const coupon = getCoupon(state);
    return state.merge({
      loading: false,
      errorMessage: null,
      data: { amount, nomination, coupon, giftCardAccountBalances },
    });
  },
  [PAYMENT_ACTION_COUPON_START]: (state) => {
    const amount = getAmount(state);
    const nomination = getNomination(state);
    const giftCardAccountBalances = getGiftCardAccountBalances(state);
    return state.merge({
      loading: true,
      errorMessage: '',
      data: { amount, nomination, giftCardAccountBalances },
    });
  },
  [PAYMENT_ACTION_COUPON_SUCCESS]: (state, action) => {
    const coupon = action.data;
    const amount = getAmount(state);
    const nomination = getNomination(state);
    const giftCardAccountBalances = getGiftCardAccountBalances(state);
    return state.merge({
      loading: false,
      errorMessage: null,
      data: { amount, nomination, coupon, giftCardAccountBalances },
    });
  },
  [PAYMENT_ACTION_COUPON_CLEAR]: (state) => {
    const amount = getAmount(state);
    const nomination = getNomination(state);
    const giftCardAccountBalances = getGiftCardAccountBalances(state);
    return state.merge({
      loading: false,
      errorMessage: null,
      data: { amount, nomination, coupon: null, giftCardAccountBalances },
    });
  },
  [PAYMENT_ACTION_COUPON_FAIL]: (state, action) => {
    // Keep amount on failure since user can retry the payment action with the same amount.
    const amount = getAmount(state);
    const nomination = getNomination(state);
    const giftCardAccountBalances = getGiftCardAccountBalances(state);
    return state.merge({
      loading: false,
      errorMessage: action.error,
      data: { amount, nomination, giftCardAccountBalances },
    });
  },
  [PAYMENT_ACTION_SUCCESS]: (state, action) => {
    const nomination = getNomination(state);
    const coupon = getCoupon(state);
    const { charge } = action.data;
    const amount = charge.amount / 100;
    const giftCardAccountBalances = getGiftCardAccountBalances(state);
    return state.merge({
      loading: false,
      errorMessage: null,
      data: { amount, nomination, charge, coupon, giftCardAccountBalances },
    });
  },
  [PAYMENT_ACTION_CLEAR]: (state) => {
    return state.merge({
      data: {},
    });
  },
  [PAYMENT_ASYNC_SUCCESS]: (state) => {
    return state.merge({
      loading: false,
    });
  },
  [PAYMENT_ASYNC_START]: (state) => {
    const amount = getAmount(state);
    const nomination = getNomination(state);
    const coupon = getCoupon(state);
    const giftCardAccountBalances = getGiftCardAccountBalances(state);
    return state.merge({
      loading: true,
      errorMessage: '',
      data: { amount, nomination, coupon, giftCardAccountBalances },
    });
  },
  [PAYMENT_ASYNC_FAIL]: (state, action) => {
    // Keep amount on failure since user can retry the payment action with the same amount.
    const amount = getAmount(state);
    const nomination = getNomination(state);
    const coupon = getCoupon(state);
    const giftCardAccountBalances = getGiftCardAccountBalances(state);
    return state.merge({
      loading: false,
      errorMessage: action.error,
      data: { amount, nomination, coupon, giftCardAccountBalances },
    });
  },
};

export default function payment(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
