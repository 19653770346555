import gql from 'graphql-tag';

export const BUDDY_ENTRY_POOL_GET = gql`
  query BUDDY_ENTRY_POOL_GET($ERAUID: ID!, $EventUID: ID!) {
    buddyEntryPoolGet(ERAUID: $ERAUID, EventUID: $EventUID) {
      FullName
      TotalAmountPaid
      createdAt
      ERAUID
      PayLater
      CompType
      AssociationFees {
        AssociationMemberTypeUID
        AssociationUID
        AssociationTypeFeeUID
        AssociationShortName
        MembershipType
        FeeAmount
        IsRenewalFee
        IsPermitFee
        IsNonMember
        AdultOnly
        YouthOnly
      }
      entries {
        EPUID
        TotalAmountPaid
        CompType
        TeammateERAUID
        TeammateName
        TeammateUserId
        HorseName
        Pref1
        Pref2
        Pref3
        Out1
        Out2
        Pref1AsDate
        Pref2AsDate
        Pref3AsDate
        Out1AsDate
        Out2AsDate
        OutIfPrefNotMet
        NominationSegments
      }
      associationMembers {
        Name
        MemberId
      }
    }
  }
`;
