// @flow
import { Record } from 'immutable';
import type { CouponType } from 'context/SelectedCoupon';
import type { PartnerAthleteType } from 'models/PartnerAthlete';
import type { EntryType } from 'models/Entry';
import type { AssociationTypeFee } from 'models/AssociationMembership';

export type EntryChargeDisciplineType = {|
  id: number,
  disciplineSubtotal: ?number,
  entryFee: ?number,
  nominationFee: ?number,
  nominationFeeSubtotal: ?number,
  nominationPerformances: string[],
  nominationSegments: number[],
  Pref1: ?number,
  Pref2: ?number,
  Pref3: ?number,
  Out1: ?number,
  Out2: ?number,
  OutIfPrefNotMet: ?boolean,
  isPayingForPartner: ?boolean,
  EPUID?: ?number,
  partner: ?PartnerAthleteType,
|};

export type EntryPreferenceDataType = {|
  Pref1: ?number,
  Pref2: ?number,
  Pref3: ?number,
  Out1: ?number,
  Out2: ?number,
  OutIfPrefNotMet: ?boolean,
|};

export type EntryChargeDisciplineFeeType = {|
  disciplineId: number,
  disciplineSubtotal: ?number,
  entryFee: ?number,
  entryPreferenceData: EntryPreferenceDataType,
  nominationFee: ?number,
  nominationFeeSubtotal: ?number,
  nominationPerformances: string[],
  disciplineFee: number,
  disciplineFeeName: ?string,
  nominationSegments: number[],
  isPayingForPartner: ?boolean,
  EPUID?: ?number,
  partner: ?PartnerAthleteType,
|};

export type NominationPayloadType = {
  disciplineId: number,
  segments: number[],
  performances: string[],
};

export type EntryChargeType = {|
  associationTypeFees: AssociationTypeFee[],
  CollectMembershipFees: ?boolean,
  CollectMembershipNumber: ?boolean,
  EntryChargeRequestUID: ?number,
  AllowEdit: ?number,
  coupon: ?CouponType,
  disciplineFees: EntryChargeDisciplineFeeType[],
  entries: EntryType[],
  entrySubtotal: ?string,
  ERAUID: ?number,
  EventUID: ?number,
  IsPaid: ?number,
  subtotalEntryAndNomination: ?number,
  subtotalTransactionFee: ?number,
  TeammateERAUID: ?number,
  totalCouponDiscount: ?number,
  totalPayment: ?number,
  totalTransactionFee: ?number,
  baseFee: ?number,
  lateFee: ?number,
  customFeeSubtotal: ?string,
  paymentType: ?string,
  isPayingForPartner: ?boolean,
|};

export const defaultEntryChargeValues: EntryChargeType = {
  associationTypeFees: [],
  CollectMembershipFees: null,
  CollectMembershipNumber: null,
  EntryChargeRequestUID: null,
  AllowEdit: null,
  coupon: null,
  disciplineFees: [],
  entries: [],
  entrySubtotal: null,
  ERAUID: null,
  EventUID: null,
  IsPaid: null,
  subtotalEntryAndNomination: null,
  subtotalTransactionFee: null,
  TeammateERAUID: null,
  totalCouponDiscount: null,
  totalPayment: 0,
  customFeeSubtotal: null,
  baseFee: 0,
  lateFee: 0,
  totalTransactionFee: null,
  paymentType: null,
  isPayingForPartner: false,
};

export default class EntryChargeRequest extends Record(
  defaultEntryChargeValues,
  'EntryChargeRequest',
) {
  constructor(values: ?EntryChargeType) {
    super({
      ...values,
    });
  }
}
