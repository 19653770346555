import client from 'api/apollo';
import { EditYouthAthleteMutation } from 'mutations/EditYouthAthlete';
import { AddYouthAthletes } from 'mutations/AddYouthAthletes';
import { getFromState, omitKeys, logError } from 'helpers';

export const YOUTHATHLETE_ASYNC_START = 'YOUTHATHLETE_ASYNC_START';
export const YOUTHATHLETE_ASYNC_FAIL = 'YOUTHATHLETE_ASYNC_FAIL';
export const YOUTHATHLETE_ASYNC_SUCCESS = 'YOUTHATHLETE_ASYNC_SUCCESS';
export const YOUTHATHLETE_ASYNC_CLEAR_ERROR = 'YOUTHATHLETE_ASYNC_CLEAR_ERROR';

export function youthAthleteAsyncStart() {
  return {
    type: YOUTHATHLETE_ASYNC_START,
  };
}

export function youthAthleteAsyncFail(errorMessage) {
  return {
    type: YOUTHATHLETE_ASYNC_FAIL,
    errorMessage,
  };
}

export function youthAthleteAsyncSuccess(data) {
  return {
    type: YOUTHATHLETE_ASYNC_SUCCESS,
    data,
  };
}

export function youthAthleteAsyncClearError() {
  return {
    type: YOUTHATHLETE_ASYNC_CLEAR_ERROR,
  };
}

export function getYouthAthlete(query, id) {
  return async (dispatch) => {
    dispatch(youthAthleteAsyncStart());
    try {
      const queryPayload = {
        query,
        variables: { id },
        fetchPolicy: 'network-only',
      };
      const response = await client
        .query(queryPayload)
        .then(({ data }) => data.youthAthlete);
      dispatch(youthAthleteAsyncSuccess(response));
      return response;
    } catch (error) {
      logError(error);
      dispatch(youthAthleteAsyncFail('Youth athlete not found'));
    }
  };
}

export function editYouthAthlete(values) {
  return async (dispatch, getState) => {
    try {
      const youthAthleteFields = omitKeys(values, [
        'id',
        'country',
        'SSN',
        'USCitizen',
        'status',
        'photoFileName',
        'isAthlete',
      ]);
      const user = getFromState(getState, 'user');
      const input = {
        userId: user.id,
        youthAthlete: youthAthleteFields,
      };
      dispatch(youthAthleteAsyncStart());
      const mutationPayload = {
        mutation: EditYouthAthleteMutation,
        variables: { input },
      };
      const response = await client
        .mutate(mutationPayload)
        .then(({ data }) => data.editYouthAthlete);
      dispatch(youthAthleteAsyncSuccess(response.youthAthlete));
      return response;
    } catch (error) {
      logError(error);
      dispatch(youthAthleteAsyncFail('Error saving youth athlete'));
    }
  };
}

export function addYouthAthletes(youthAthletes) {
  return async (dispatch, getState) => {
    try {
      const youthAthleteFields = youthAthletes.map((ya) =>
        omitKeys(ya, ['id', 'country', 'SSN', 'USCitizen']),
      );
      const user = getFromState(getState, 'user');
      const input = {
        userId: user.id,
        youthAthletes: youthAthleteFields,
      };
      const mutationPayload = {
        mutation: AddYouthAthletes,
        variables: { input },
      };
      dispatch(youthAthleteAsyncStart());
      const response = await client
        .mutate(mutationPayload)
        .then(({ data }) => data);
      dispatch(youthAthleteAsyncSuccess({}));
      return response.addYouthAthletes;
    } catch (error) {
      logError(error);
      dispatch(youthAthleteAsyncFail('Error adding youth athlete'));
    }
  };
}
