import { canConvertToJS } from './canConvertToJS';

const objectReturn = (value) =>
  Object.entries(value).reduce((newProps, [propKey, propValue]) => {
    newProps[propKey] = canConvertToJS(propValue)
      ? propValue.toJS()
      : propValue;
    return newProps;
  }, {});

const arrayReturn = (value) =>
  value.reduce((newProps, curr) => {
    const currToJS = canConvertToJS(curr) ? curr.toJS() : curr;
    newProps.push(currToJS);
    return newProps;
  }, []);

export const toJS = (target) => {
  const value = canConvertToJS(target) ? target.toJS() : target;
  return Array.isArray(value) ? arrayReturn(value) : objectReturn(value);
};
