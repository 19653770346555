// @flow
import { getNestedProperty } from './getNestedProperty';
const DATE_STRING_LENGTH = 8;

export const isValidMomentDate = (momentDate: ?moment$Moment) => {
  if (!momentDate) return false;

  return (
    getNestedProperty('_i', momentDate, '').replace(/[^\d]/g, '').length ===
    DATE_STRING_LENGTH
  );
};
