// @flow
import { getNestedProperty } from 'helpers';
import type { DisciplineType } from 'context/SelectedDisciplines/type';

export const getDisciplineSegmentsText = (discipline: DisciplineType) => {
  const segments =
    getNestedProperty('segments', discipline) ||
    getNestedProperty('NominationSegments', discipline);
  if (!segments || !segments.length) {
    return '';
  }

  const segmentsText = segments.reduce((prev, curr, idx) => {
    const isLast = idx === segments.length - 1;
    const comma = isLast ? '' : ', ';
    const newText =
      typeof curr === 'string'
        ? `Segment ${curr}${comma}`
        : `Segment ${curr.ShortName}${comma}`;
    prev += newText;
    return prev;
  }, '');

  return segmentsText;
};
