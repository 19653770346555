import { Record, Map } from 'immutable';

const defaultValues = {
  loading: false,
  errorMessage: null,
  data: Record(),
  selected: Map(),
};

export default class BaseSelect extends Record(defaultValues, BaseSelect) {
  constructor(values) {
    super({
      ...values,
    });
  }

  addSelection(key, value) {
    return this.update(`selected`, (selectedMap) => {
      const updateSelected = selectedMap[key]
        ? {
            ...selectedMap,
            [key]: [...selectedMap[key], value],
          }
        : {
            ...selectedMap,
            [key]: [value],
          };
      return updateSelected;
    });
  }

  removeSelection(key, valueFilter) {
    return this.update(`selected`, (selectedMap) => {
      const updateSelected = selectedMap[key]
        ? {
            ...selectedMap,
            [key]: valueFilter(selectedMap[key]),
          }
        : selectedMap;
      return updateSelected;
    });
  }
}
