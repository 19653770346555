// @flow
import moment from 'moment-timezone';
import TIME_ZONE_UIDS from '../constants/timeZoneUID';

export const formatDateTimeToCST = (
  dateTime: string = '',
  TimeZoneUID: number = 0,
): string => {
  if (!dateTime) return '';
  const timeZone = TimeZoneUID
    ? `[${
        TIME_ZONE_UIDS.find((item) => TimeZoneUID === item.timeZoneUID).timeZone
      }]`
    : '';
  const formattedDateTime = moment
    .utc(dateTime)
    .format(`M/DD/YYYY [AT] h:mm A ${timeZone}`);
  return formattedDateTime;
};
