import gql from 'graphql-tag';

export const REMOVE_AS_PARTNER = gql`
  mutation editMyEntryPartner($input: editPartnerInputType!) {
    editMyEntryPartner(input: $input) {
      EntryChargeRequestUID
      EPUID
      EventUID
      ERAUID
      PayingERAUID
      userId
      CompType
      EventName
      FullName
      Discipline
      email
      TeammateERAUID
      PaymentStatus
      PartnerPaymentStatus
      TeammateName
      PaymentType
      PartnerEPUID
    }
  }
`;
