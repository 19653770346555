import { Record } from 'immutable';

const defaultValues = {
  TypeUID: null,
  TypeName: '',
};

export default class EventType extends Record(defaultValues, 'EventType') {
  constructor(values) {
    super({
      ...values,
    });
  }
}
