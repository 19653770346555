import moment from 'moment';

export const nominateYouthAgeVerify = (DOB) => {
  const formattedDOB = moment(DOB, 'MMDDYYYY').format('YYYY-MM-DD');

  const minAge = moment()
    .subtract(13, 'years')
    .format('YYYY-MM-DD');

  return !moment(formattedDOB).isBefore(minAge);
};
