import client from 'api/apollo';
import { getFromState, logError, getNestedProperty } from 'helpers';

import { BUDDY_ENTRY_POOL_GET } from 'queries/buddyEntryPoolGet';
import { BUDDY_ENTRY_POOL_TRANSACTIONS_GET } from 'queries/buddyEntryPoolTransactionsGet';

export const ENTRY_POOL_ASYNC_START = 'ENTRY_POOL_ASYNC_START';
export const ENTRY_POOL_ASYNC_STOP = 'ENTRY_POOL_ASYNC_STOP';
export const ENTRY_POOL_ASYNC_FAIL = 'ENTRY_POOL_ASYNC_FAIL';
export const ENTRY_POOL_ASYNC_SUCCESS = 'ENTRY_POOL_ASYNC_SUCCESS';
export const ENTRY_POOL_CLEAR_BUDDY_GROUP = 'ENTRY_POOL_CLEAR_BUDDY_GROUP';
export const ENTRY_POOL_ADD_MANAGER_TO_BUDDY_GROUP =
  'ENTRY_POOL_ADD_MANAGER_TO_BUDDY_GROUP';
export const ENTRY_POOL_REMOVE_MANAGER_FROM_BUDDY_GROUP =
  'ENTRY_POOL_REMOVE_MANAGER_FROM_BUDDY_GROUP';
export const ENTRY_POOL_GET_BUDDY_GROUP_ENTRIES_SUCCESS =
  'ENTRY_POOL_GET_BUDDY_GROUP_ENTRIES_SUCCESS';
export const ENTRY_POOL_GET_ENTRY_TRANSACTIONS_SUCCESS =
  'ENTRY_POOL_GET_ENTRY_TRANSACTIONS_SUCCESS';

function entryPoolAsyncStart() {
  return {
    type: ENTRY_POOL_ASYNC_START,
  };
}

function entryPoolAsyncFail(error) {
  return {
    type: ENTRY_POOL_ASYNC_FAIL,
    error,
  };
}

function getBuddyGroupSuccess(data) {
  return {
    type: ENTRY_POOL_GET_BUDDY_GROUP_ENTRIES_SUCCESS,
    data,
  };
}

function getEntryTransactionsSuccess(data) {
  return {
    type: ENTRY_POOL_GET_ENTRY_TRANSACTIONS_SUCCESS,
    data,
  };
}

export function clearBuddyGroup() {
  return {
    type: ENTRY_POOL_CLEAR_BUDDY_GROUP,
  };
}

export function addManagerToBuddyGroup(data) {
  return {
    type: ENTRY_POOL_ADD_MANAGER_TO_BUDDY_GROUP,
    data,
  };
}

export function removeManagerFromBuddyGroup() {
  return {
    type: ENTRY_POOL_REMOVE_MANAGER_FROM_BUDDY_GROUP,
  };
}

export function getBuddyGroup() {
  return async (dispatch, getState) => {
    dispatch(entryPoolAsyncStart());

    const { id } = getFromState(getState, 'event');
    const user = getFromState(getState, 'user');
    const ERAUID = getNestedProperty('ERAUID', user);

    try {
      const buddyGroupPayload = {
        query: BUDDY_ENTRY_POOL_GET,
        variables: { ERAUID, EventUID: id },
        fetchPolicy: 'network-only',
      };
      const response = await client.query(buddyGroupPayload);

      dispatch(getBuddyGroupSuccess(response.data.buddyEntryPoolGet));
      return response;
    } catch (error) {
      logError(error);
      const err = 'Problem fetching buddy group.';
      dispatch(entryPoolAsyncFail(err));

      return null;
    }
  };
}

export function inviteBuddyPartnerSuccess(ERAUID) {
  return async (dispatch, getState) => {
    const entryPool = getState()['entryPool'].toJS();

    const buddyEntries = entryPool.buddyGroup.map((buddy) => {
      const entries = buddy.entries.map((buddyEntry) => {
        if (buddyEntry.TeammateERAUID === ERAUID) {
          return { ...buddyEntry, invited: true };
        }
        return buddyEntry;
      });

      return { ...buddy, entries };
    });

    dispatch({
      type: ENTRY_POOL_GET_BUDDY_GROUP_ENTRIES_SUCCESS,
      data: buddyEntries,
    });
  };
}

export function getEntryTransactionsForEvent(EventUID, ERAUID) {
  return async (dispatch) => {
    dispatch(entryPoolAsyncStart());

    try {
      const eventPayload = {
        query: BUDDY_ENTRY_POOL_TRANSACTIONS_GET,
        variables: { EventUID, ERAUID },
        fetchPolicy: 'network-only',
      };
      const response = await client.query(eventPayload);
      const transactions = response.data.athleteTransactionsEntriesForEvent;

      dispatch(getEntryTransactionsSuccess(transactions));

      return transactions;
    } catch (error) {
      logError(error);
      const err = 'There was a problem getting transactions, please try again.';
      dispatch(entryPoolAsyncFail(err));

      return null;
    }
  };
}
