import Leaderboard from 'models/Leaderboard';
import Base from 'models/Base';

import {
  LEADERBOARD_ASYNC_START,
  LEADERBOARD_ASYNC_SUCCESS,
  LEADERBOARD_ASYNC_FAIL,
} from 'actions/leaderboard';

const initialState = new Base({
  loading: false,
  errorMessage: '',
  data: new Leaderboard(),
});

const actionsMap = {
  [LEADERBOARD_ASYNC_START]: (state) => {
    return state.merge({
      loading: true,
      errorMessage: '',
    });
  },
  [LEADERBOARD_ASYNC_SUCCESS]: (state, action) => {
    const { leaders } = action.data;
    return state.merge({
      loading: false,
      errorMessage: null,
      data: new Leaderboard({ leaders }),
    });
  },
  [LEADERBOARD_ASYNC_FAIL]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.error,
    });
  },
};

export default function leaderboard(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
