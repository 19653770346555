import ReactPixel from 'react-facebook-pixel';

import api from 'api';
import {
  getFromState,
  getAccessToken,
  logError,
  getNestedProperty,
} from 'helpers';

import { EVENT_ENTRY_SUCCESS } from 'constants/routes';
import { PAY_LATER } from 'constants/paymentTypes';

import { history, changeRoute } from 'helpers';

export const ENTRY_CHARGE_ACTION_SET_TEAMMATEERAUID =
  'ENTRY_CHARGE_ACTION_SET_TEAMMATEERAUID';
export const ENTRY_CHARGE_ACTION_UNSET_TEAMMATEERAUID =
  'ENTRY_CHARGE_ACTION_UNSET_TEAMMATEERAUID';
export const ENTRY_CHARGE_ACTION_SUCCESS = 'ENTRY_CHARGE_ACTION_SUCCESS';
export const ENTRY_CHARGE_ACTION_CLEAR = 'ENTRY_CHARGE_ACTION_CLEAR';
export const ENTRY_CHARGE_ASYNC_START = 'ENTRY_CHARGE_ASYNC_START';
export const ENTRY_CHARGE_ASYNC_STOP = 'ENTRY_CHARGE_ASYNC_STOP';
export const ENTRY_CHARGE_ASYNC_FAIL = 'ENTRY_CHARGE_ASYNC_FAIL';
export const ENTRY_CHARGE_ASYNC_SUCCESS = 'ENTRY_CHARGE_ASYNC_SUCCESS';
export const ENTRY_CHARGE_LIST_ASYNC_SUCCESS =
  'ENTRY_CHARGE_LIST_ASYNC_SUCCESS';
export const ENTRY_CHARGE_ACTION_CLEAR_ERROR =
  'ENTRY_CHARGE_ACTION_CLEAR_ERROR';

export const ENTRY_PAYMENT_ACTION_SUCCESS = 'ENTRY_PAYMENT_ACTION_SUCCESS';
export const ENTRY_PAYMENT_ASYNC_START = 'ENTRY_PAYMENT_ASYNC_START';
export const ENTRY_PAYMENT_ASYNC_FAIL = 'ENTRY_PAYMENT_ASYNC_FAIL';
export const ENTRY_INVITE_PARTNER_SUCCESS = 'ENTRY_INVITE_PARTNER_SUCCESS';

export function setTeammateERAUIDAction(data) {
  return {
    type: ENTRY_CHARGE_ACTION_SET_TEAMMATEERAUID,
    data,
  };
}

export function unsetTeammateERAUIDAction(data) {
  return {
    type: ENTRY_CHARGE_ACTION_UNSET_TEAMMATEERAUID,
    data,
  };
}

function entryChargeActionSuccess(data) {
  return {
    type: ENTRY_CHARGE_ACTION_SUCCESS,
    data,
  };
}

function entryChargeActionClear(data) {
  return {
    type: ENTRY_CHARGE_ACTION_CLEAR,
    data,
  };
}

function entryChargeAsyncStart() {
  return {
    type: ENTRY_CHARGE_ASYNC_START,
  };
}

function entryChargeAsyncStop() {
  return {
    type: ENTRY_CHARGE_ASYNC_STOP,
  };
}

function entryChargeAsyncSuccess(data) {
  return {
    type: ENTRY_CHARGE_ASYNC_SUCCESS,
    data,
  };
}

function entryChargeAsyncFail(error) {
  return {
    type: ENTRY_CHARGE_ASYNC_FAIL,
    error,
  };
}

export function entryChargeClearError() {
  return {
    type: ENTRY_CHARGE_ACTION_CLEAR_ERROR,
  };
}

function paymentAsyncStart() {
  return {
    type: ENTRY_PAYMENT_ASYNC_START,
  };
}

function paymentActionSuccess(data) {
  return {
    type: ENTRY_PAYMENT_ACTION_SUCCESS,
    data,
  };
}

function paymentAsyncFail(error) {
  return {
    type: ENTRY_PAYMENT_ASYNC_FAIL,
    error,
  };
}

const findEvent = (events = [], id) =>
  events.filter(({ EventUID }) => EventUID === id) || [];

const getAllEntries = (
  id,
  { confirmedEntries, pendingEntries, pendingWithMembershipEntries },
) => {
  const confirmed = (findEvent(confirmedEntries, id)[0] || {}).entries;
  const pending = (findEvent(pendingEntries, id)[0] || {}).entries;
  const pendingWithMembership = (
    findEvent(pendingWithMembershipEntries, id)[0] || {}
  ).entries;

  return [].concat(confirmed || [], pending || [], pendingWithMembership || []);
};

export function calculateEntryCharge(values) {
  return async (dispatch, getState) => {
    const { authPayload } = getFromState(getState, 'auth');
    const accessToken = getAccessToken(authPayload);
    const user = getFromState(getState, 'user');
    const entries = getAllEntries(
      values.EventUID,
      getNestedProperty('entryData', user),
    );

    dispatch(entryChargeAsyncStart());
    try {
      const response = await api({
        path: 'event/entries/calculate-charges',
        method: 'POST',
        body: values,
        accessToken,
      });

      if (response) {
        response.entries = entries;
      }

      dispatch(entryChargeAsyncSuccess(response));
      return response;
    } catch (error) {
      logError(error);
      let err = 'An error occurred while trying to calculate charges';
      if (error.message) {
        err = error.message;
      }
      dispatch(entryChargeAsyncFail(err));
      return { error: { message: err } };
    }
  };
}

export function selectEntryCharge(entryCharge) {
  return (dispatch) => {
    dispatch(entryChargeActionSuccess(entryCharge));
  };
}

export function clearEntryCharge() {
  return (dispatch) => {
    dispatch(entryChargeActionClear());
  };
}

export function stripeRequestTokenStart() {
  return async (dispatch) => {
    dispatch(paymentAsyncStart());
  };
}

export function stripeRequestTokenFail(error) {
  return async (dispatch) => {
    dispatch(paymentAsyncFail(error));
  };
}

export function submitEntryPayment(values) {
  return async (dispatch, getState) => {
    dispatch(paymentAsyncStart());
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const { selectedMembershipStatus } = getFromState(
        getState,
        'associationMembership',
      );
      selectedMembershipStatus;
      const accessToken = getAccessToken(authPayload);
      const response = await api({
        path: 'payment/pay-for-entry',
        method: 'POST',
        body: { ...values, selectedMembershipStatus },
        accessToken,
      });

      if (!response.err && !response.error) {
        dispatch(paymentActionSuccess(response));
      }

      if (response && response.EntryChargeRequestUID) {
        const { totalCostOfEntry, paymentType } = response;
        const amount = paymentType === PAY_LATER ? '0.00' : totalCostOfEntry;

        ReactPixel.track('Purchase', {
          currency: 'USD',
          value: amount,
          content_ids: ['entry'],
        });

        changeRoute({
          history,
          route: EVENT_ENTRY_SUCCESS,
          persistState: true,
        });
      }
    } catch (error) {
      logError(error);
      const err = getNestedProperty(
        'message',
        error,
        'An error occurred while trying to create payment',
      );
      dispatch(paymentAsyncFail(err));
      return null;
    }
  };
}

export function searchTeammate(searchTerm) {
  return async (dispatch, getState) => {
    const { authPayload } = getFromState(getState, 'auth');
    const accessToken = getAccessToken(authPayload);

    dispatch(entryChargeAsyncStart());
    try {
      const response = await api({
        path: `contestants/searchTeammate?searchTerm=${searchTerm}`,
        method: 'GET',
        accessToken,
      });
      dispatch(entryChargeAsyncStop());
      return response;
    } catch (error) {
      logError(error);
      dispatch(entryChargeAsyncStart());
      return null;
    }
  };
}

export function getTeammate(TeammateUID) {
  return async (dispatch, getState) => {
    const { authPayload } = getFromState(getState, 'auth');
    const accessToken = getAccessToken(authPayload);

    dispatch(entryChargeAsyncStart());
    try {
      const response = await api({
        path: `contestants/searchTeammate/${TeammateUID}`,
        method: 'GET',
        accessToken,
      });
      dispatch(entryChargeAsyncStop());
      return response;
    } catch (error) {
      logError(error);
      dispatch(entryChargeAsyncStart());
      return null;
    }
  };
}

export function invitePartnerSuccess(ERAUID) {
  return async (dispatch, getState) => {
    const entryChargeRequest = getFromState(getState, 'entryChargeRequest');

    const entries = entryChargeRequest.entries.map((entry) => {
      if (entry.TeammateERAUID === ERAUID) {
        return { ...entry, invited: true };
      }

      return entry;
    });

    const data = { ...entryChargeRequest, entries };

    dispatch({ type: ENTRY_CHARGE_ACTION_SUCCESS, data });
  };
}
