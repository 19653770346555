// @flow
import { getNestedProperty } from './';

type PreferenceType = {|
  Pref1: boolean,
  Pref2: boolean,
  Pref3: boolean,
  Out1: boolean,
  Out2: boolean,
  Pref1AsDate: string,
  Pref2AsDate: string,
  Pref3AsDate: string,
  Out1AsDate: string,
  Out2AsDate: string,
  performances?: Object[],
|};

const hasPrefId = (item, prefId) => {
  return (
    item.Pref1 === prefId ||
    item.Pref2 === prefId ||
    item.Pref3 === prefId ||
    item.Out1 === prefId ||
    item.Out2 === prefId
  );
};

export const hasPreferences = (
  item: PreferenceType,
  checkDates: boolean = false,
): boolean => {
  const hasPrefs =
    !!item.Pref1 || !!item.Pref2 || !!item.Pref3 || !!item.Out1 || !!item.Out2;
  if (!checkDates) {
    return hasPrefs;
  }

  const performances = getNestedProperty('performances', item, []);
  const hasPrefDates =
    !!item.Pref1AsDate ||
    !!item.Pref2AsDate ||
    !!item.Pref3AsDate ||
    !!item.Out1AsDate ||
    !!item.Out2AsDate;
  const hasMatchingPref =
    performances.length > 0
      ? performances.some((performance) =>
          hasPrefId(item, performance.PerformanceUID),
        )
      : false;

  const hasMatchingPrefDates = hasPrefDates ? hasPrefDates : hasMatchingPref;
  return hasPrefs && hasMatchingPrefDates;
};
