// @flow
import type { EntryType } from 'models/Entry';
import type { EventType } from 'models/Event';
import type { DisciplineType } from 'context/SelectedDisciplines/type';

const hasAllPreferenceKeys = (discipline: DisciplineType | EntryType) => {
  return ['Pref1', 'Pref2', 'Pref3', 'Out1', 'Out2'].every(
    (item) => discipline[item] !== undefined,
  );
};

type PreferencesAndOutDetails = {
  selectedPreferencesDetails: Object[],
  selectedOutDetails: Object[],
};

export const preferencesAndOutDetails = (
  discipline: DisciplineType | EntryType,
  event: EventType,
): PreferencesAndOutDetails => {
  const { Pref1, Pref2, Pref3, Out1, Out2, hasPreference } = discipline;
  if (!hasPreference && !hasAllPreferenceKeys(discipline)) {
    return { selectedPreferencesDetails: [], selectedOutDetails: [] };
  }
  const performanceObj = {};
  event.performances.forEach(
    (performance) => (performanceObj[performance.PerformanceUID] = performance),
  );

  const selectedPreferences = [
    { type: 'PREF', key: '1', PerformanceUID: Number(Pref1) },
    { type: 'PREF', key: '2', PerformanceUID: Number(Pref2) },
    { type: 'PREF', key: '3', PerformanceUID: Number(Pref3) },
  ].filter(({ PerformanceUID }) => !!PerformanceUID);

  const selectedOut = [
    { type: 'NOT', key: '1', PerformanceUID: Number(Out1) },
    { type: 'NOT', key: '2', PerformanceUID: Number(Out2) },
  ].filter(({ PerformanceUID }) => !!PerformanceUID);

  const getPreferencesOutDetails = ({ type, key, PerformanceUID }) => ({
    type,
    key,
    ...performanceObj[PerformanceUID],
  });

  const selectedPreferencesDetails = selectedPreferences.map(
    getPreferencesOutDetails,
  );

  const selectedOutDetails = selectedOut.map(getPreferencesOutDetails);

  return { selectedPreferencesDetails, selectedOutDetails };
};
