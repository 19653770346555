import gql from 'graphql-tag';

export const BUDDY_ENTRY_POOL_TRANSACTIONS_GET = gql`
  query getEventTransactions($ERAUID: ID!, $EventUID: ID!) {
    athleteTransactionsEntriesForEvent(ERAUID: $ERAUID, EventUID: $EventUID) {
      date
      totalFee
      entryChargeRequestUID
      isPaying
      isBuddyTransaction
      payeeFullName
      PayLater
      entries {
        disciplineId
        EPUID
        ClassName
        partnerFullName
        HorseName
        prefCount
        notCount
        Pref1
        Pref1AsDate
        Pref2
        Pref2AsDate
        Pref3
        Pref3AsDate
        Out1
        Out1AsDate
        Out2
        Out2AsDate
        NominationSegments
      }
    }
  }
`;
