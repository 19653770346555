import gql from 'graphql-tag';

export const YouthAthleteFragments = {
  all: gql`
    fragment YouthAthleteAll on YouthAthlete {
      id
      firstName
      lastName
      ERAUID
      DOB
      Gender
      disciplineId
      phone
      street
      street2
      city
      DOBVerified
      state
      zip
      socialTags
      photoFilename
      nickname
      hometown
      careerEarnings
      worldChampionshipEarned
      height
      weight
      facebook
      twitter
      instagram
      isAthlete
    }
  `,
  disciplineId: gql`
    fragment YouthAthleteDisciplineId on YouthAthlete {
      disciplineId
    }
  `,
};
