// @flow
export const getEntryLimitText = (
  maxNumberOfEntries: number,
  getEntryLimitText: number,
): string => {
  const entriesRemaining = Math.max(maxNumberOfEntries - getEntryLimitText, 0);
  const entryLimitText =
    getEntryLimitText < 1
      ? `Limit: ${maxNumberOfEntries} entries`
      : `Limit: ${entriesRemaining} of ${maxNumberOfEntries} entries remaining`;
  return entryLimitText;
};
