import { isTeamDiscipline } from 'constants/disciplines';

export const getGroupedEntries = (
  entries,
  selectedDisciplines,
  allowClasses,
) => {
  if (!entries.length) {
    return null;
  }
  const entriesWithoutPartnerEntries = entries.filter((entry) => {
    return !entry.isPartner;
  });

  let indexPartner = 0;
  let currentDisciplineValue =
    selectedDisciplines && selectedDisciplines.length
      ? selectedDisciplines[0].value
      : 0;

  let currentClassName =
    selectedDisciplines && selectedDisciplines.length
      ? selectedDisciplines[0].ClassName
      : '';

  const currentEntries = entriesWithoutPartnerEntries.map((entry) => {
    if (
      currentDisciplineValue != entry.CompType ||
      currentClassName != entry.ClassName
    ) {
      indexPartner = 0;
      currentDisciplineValue = entry.CompType;
      currentClassName = entry.ClassName;
    }

    let currentDiscipline = null;
    if (allowClasses) {
      currentDiscipline = selectedDisciplines.find(
        (discipline) =>
          discipline.value === entry.CompType &&
          discipline.ClassName === entry.ClassName,
      );
    } else {
      currentDiscipline = selectedDisciplines.find(
        (discipline) => discipline.value === entry.CompType,
      );
    }

    const { partner = {}, isPendingEntry } = currentDiscipline;
    let { partners } = currentDiscipline;
    let currentEntry = null;

    if (isPendingEntry) {
      currentEntry = {
        ...entry,
        partnerName:
          partner && isTeamDiscipline(entry.CompType) ? partner.fullName : '',
      };
    } else {
      partners = Object.values(currentDiscipline.partners);
      let partnerName = entry.partnerName;
      if (
        isTeamDiscipline(entry.CompType) &&
        partners[indexPartner] &&
        partners[indexPartner].id
      ) {
        partnerName = partnerName || partners[indexPartner].id;
      }

      currentEntry = { ...entry, partnerName };
    }
    indexPartner = indexPartner + 1;
    return currentEntry;
  });

  if (allowClasses) {
    return currentEntries.reduce((rv, x) => {
      (rv[`${x['CompType']}-${x['ClassName']}`] =
        rv[`${x['CompType']}-${x['ClassName']}`] || []).push(x);
      return rv;
    }, {});
  }
  return currentEntries.reduce((rv, x) => {
    (rv[x['CompType']] = rv[x['CompType']] || []).push(x);
    return rv;
  }, {});
};
