import { Record } from 'immutable';

const defaultValues = {
  nominations: [],
};

export default class UserNominations extends Record(
  defaultValues,
  UserNominations,
) {
  constructor(values) {
    super({
      ...values,
    });
  }
}
