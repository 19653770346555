/**
 * Returns a destructurable array containing subscribe, publish and reject
 * functions.  Subscribe returns a promise with each call.  Any call to publish
 * resolves the promises with any value passed.  Reject will cause the promises
 * to throw any error passed.
 */
const observe = () => {
  const state = { resolve: false, reject: false };

  const subscribe = () =>
    new Promise((res, rej) => {
      state.resolve = res;
      state.reject = rej;
    });

  const publish = (result) => (state.resolve ? state.resolve(result) : false);
  const reject = (error) => (state.reject ? state.reject(error) : false);

  return [subscribe, publish, reject];
};

export default observe;
