import { getFormattedDollarAmount } from './';
export const getCouponDescription = (coupon) => {
  if (!coupon) return '';

  if (coupon.isPercentage) {
    const nominationDiscount = coupon.NomDisc * 100;
    const transactionFeeDiscount = coupon.FeeDisc * 100;
    return `${nominationDiscount}% off nomination fee & ${transactionFeeDiscount}% off nomination transaction fee`;
  }

  return `${getFormattedDollarAmount(coupon.DollarDisc)} off nomination fee`;
};
