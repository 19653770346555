import gql from 'graphql-tag';

export const AddYouthAthletes = gql`
  mutation AddYouthAthlete($input: AddYouthAthletesInput!) {
    addYouthAthletes(input: $input) {
      success
      error
    }
  }
`;
