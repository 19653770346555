import observe from './lib/observe';

const timeoutInMinutes = process.env.INACTIVITY_TIMEOUT_IN_MINUTES || 60;
const timeoutInMs = timeoutInMinutes * 60000;
const notBreak = true;

// Create "await" functions and resolvers to track activity, timeout and logout
const [activitySubscribe, activityPublish] = observe();
const [logoutSubscribe, logoutPublish] = observe();

const timeoutWait = (t) =>
  new Promise((resolve) => {
    setTimeout(() => resolve('timeout'), t);
  });

// Saga starting at login, ending on logout
const startActivitySaga = async (dispatch) => {
  document.addEventListener('click', activityPublish);
  document.addEventListener('keydown', activityPublish);

  while (notBreak) {
    const result = await Promise.race([
      timeoutWait(timeoutInMs),
      activitySubscribe(),
      logoutSubscribe(),
    ]);

    if (result === 'timeout') dispatch({ type: 'DE_AUTH' });
    if (result === 'timeout' || result === 'logout') break;
  }

  document.removeEventListener('click', activityPublish);
  document.removeEventListener('keydown', activityPublish);
};

// Export the redux middleware, starting the saga on receipt of user
// information and ending on logout.
export default function({ dispatch }) {
  return (next) => async (action) => {
    if (typeof action !== 'object') return next(action);

    switch (action.type) {
      case 'AUTH_ASYNC_SUCCESS':
      case 'persist/REHYDRATE':
        startActivitySaga(dispatch);
        break;
      case 'DE_AUTH':
        logoutPublish('logout');
        break;
    }
    next(action);
  };
}
