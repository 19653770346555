import { Record } from 'immutable';
import { toEntityList } from 'models/BaseList';

const defaultValues = {
  loading: false,
  errorMessage: null,
  data: Record(),
  list: toEntityList([], Record),
};

export default class Base extends Record(defaultValues, Base) {
  constructor(values) {
    super({
      ...values,
    });
  }
}
