import gql from 'graphql-tag';

import { YouthAthleteFragments } from 'fragments/YouthAthlete';

export const EditYouthAthleteMutation = gql`
  mutation EditYouthAthleteMutation($input: EditYouthAthleteInput!) {
    editYouthAthlete(input: $input) {
      youthAthlete {
        ...YouthAthleteAll
      }
    }
  }
  ${YouthAthleteFragments.all}
`;
