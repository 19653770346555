export const getPartnerAthleteInfo = (athlete) => {
  const { id, fullNameWithNickname, city, state } = athlete;
  const partnerAthleteInfo = {
    value: id,
  };

  if (fullNameWithNickname && fullNameWithNickname.length > 0) {
    partnerAthleteInfo.fullNameWithNickname = fullNameWithNickname;
  }

  if (city && state) {
    partnerAthleteInfo.location = `${city}, ${state}`;
  } else if (city) {
    partnerAthleteInfo.location = city;
  } else if (state) {
    partnerAthleteInfo.location = state;
  }

  return partnerAthleteInfo;
};
