import AsyncComponentHOC from 'components/HOC/AsyncComponentHOC';
import { arrayToObject } from 'helpers';
import { routeTypes, routeCodes, routesMap } from './index';

const {
  BASE_ROUTES,
  NOMINATION_ROUTES,
  ENTRY_ROUTES,
  LEADERBOARD_ROUTES,
  GIFT_CARD_ROUTES,
} = routeCodes;
// BASE
const AsyncHome = AsyncComponentHOC(() => import('containers/Home'));
const AsyncLogin = AsyncComponentHOC(() => import('containers/Login'));
const AsyncLoginWith = AsyncComponentHOC(() =>
  import('containers/Login/LoginWith'),
);
const AsyncAuth0 = AsyncComponentHOC(() => import('containers/Login/Auth0'));
const AsyncRegistration = AsyncComponentHOC(() =>
  import('containers/Registration'),
);
const AsyncForgotPassword = AsyncComponentHOC(() =>
  import('containers/Login/Forgot'),
);
const AsyncResetPassword = AsyncComponentHOC(() =>
  import('containers/Login/Reset'),
);
const AsyncDashboard = AsyncComponentHOC(() => import('containers/Dashboard'));
const AsyncDashboardComplete = AsyncComponentHOC(() =>
  import('containers/Dashboard/Complete'),
);
const AsyncPrivacyPolicy = AsyncComponentHOC(() =>
  import('containers/LegalLinks/PrivacyPolicy'),
);
const AsyncTermsOfUse = AsyncComponentHOC(() =>
  import('containers/LegalLinks/TermsOfUse'),
);
const AsyncUserAgreement = AsyncComponentHOC(() =>
  import('containers/LegalLinks/UserAgreement'),
);
const AsyncProfile = AsyncComponentHOC(() => import('containers/Profile'));
const AsyncProfileEdit = AsyncComponentHOC(() =>
  import('containers/Profile/Edit'),
);
const AsyncEditYouthAthlete = AsyncComponentHOC(() =>
  import('containers/Profile/EditYouthAthlete'),
);
const AsyncSettings = AsyncComponentHOC(() => import('containers/Settings'));
const AsyncSettingsEmailEdit = AsyncComponentHOC(() =>
  import('containers/Settings/EmailEdit'),
);
const AsyncSettingsCardEdit = AsyncComponentHOC(() =>
  import('containers/Settings/CardEdit'),
);
const AsyncSettingsPasswordEdit = AsyncComponentHOC(() =>
  import('containers/Settings/PasswordEdit'),
);
const AsyncManageChildren = AsyncComponentHOC(() =>
  import('containers/ManageChildren'),
);
const AsyncManageChildrenView = AsyncComponentHOC(() =>
  import('containers/Profile/ViewYouthAthlete'),
);

// LEADERBOARD
const AsyncLeaderboard = AsyncComponentHOC(() =>
  import('containers/Leaderboard'),
);
const AsyncMyResults = AsyncComponentHOC(() => import('containers/MyResults'));
const AsyncResults = AsyncComponentHOC(() => import('containers/MyResults'));

// NOMINATION
const AsyncNominate = AsyncComponentHOC(() =>
  import('containers/NominateHome'),
);
const AsyncNominateClass = AsyncComponentHOC(() =>
  import('containers/NominateClass'),
);
const AsyncNominateSearch = AsyncComponentHOC(() =>
  import('containers/Nominate/Search'),
);
const AsyncUpcomingEvents = AsyncComponentHOC(() =>
  import('containers/Nominate/UpcomingEvents'),
);
const AsyncNominateSuccess = AsyncComponentHOC(() =>
  import('containers/Nominate/Success'),
);
const AsyncNominateCheckout = AsyncComponentHOC(() =>
  import('containers/Checkout'),
);
const AsyncNominateReview = AsyncComponentHOC(() =>
  import('containers/NominateReview'),
);
const AsyncNominatePerformances = AsyncComponentHOC(() =>
  import('containers/NominatePerformances'),
);
const AsyncNominatePoints = AsyncComponentHOC(() =>
  import('containers/NominatePoints'),
);

// ENTRY
const AsyncEventEntry = AsyncComponentHOC(() => import('containers/Entry'));
const AsyncEventEntrySearch = AsyncComponentHOC(() =>
  import('containers/EntrySearch'),
);
const AsyncEventEntryCollectMembershipNumber = AsyncComponentHOC(() =>
  import('containers/EntryCollectMembershipNumber'),
);
const AsyncEventEntryCollectMembershipFees = AsyncComponentHOC(() =>
  import('containers/EntryCollectMembershipFees'),
);
const AsyncEventEntryLegalInfo = AsyncComponentHOC(() =>
  import('containers/EntryLegalInfo'),
);
const AsyncEventEntryYouthAthleteInfo = AsyncComponentHOC(() =>
  import('containers/EntryYouthAthleteInfo'),
);
const AsyncEventEntryCheckout = AsyncComponentHOC(() =>
  import('containers/EntryCheckout'),
);
const AsyncEventEntrySuccess = AsyncComponentHOC(() =>
  import('containers/EntrySuccess'),
);
const AsyncEventEntryTransactions = AsyncComponentHOC(() =>
  import('containers/EntryTransactions'),
);
const AsyncUpcomingEventsEntries = AsyncComponentHOC(() =>
  import('containers/EntryUpcomingEvents'),
);
const AsyncEventEntryPreferences = AsyncComponentHOC(() =>
  import('containers/EntryPreferences'),
);
const AsyncEventEntryReview = AsyncComponentHOC(() =>
  import('containers/EntryReview'),
);

// GIFT_CARD
const AsyncDownloadGiftCardPreview = AsyncComponentHOC(() =>
  import('containers/GiftCard/GiftCardPreview.jsx'),
);

const AsyncGiftCardList = AsyncComponentHOC(() =>
  import('containers/GiftCard'),
);
const AsyncGiftCardLoadBalance = AsyncComponentHOC(() =>
  import('containers/GiftCard/LoadBalance'),
);

const componentRouteMap = [
  // BASE
  {
    path: BASE_ROUTES.INDEX,
    component: AsyncHome,
    type: routeTypes.UNAUTHENTICATED,
  },
  {
    path: BASE_ROUTES.AUTH0,
    component: AsyncAuth0,
    type: routeTypes.UNAUTHENTICATED,
  },
  {
    path: BASE_ROUTES.LOGIN,
    component: AsyncLogin,
    type: routeTypes.UNAUTHENTICATED,
  },
  {
    path: BASE_ROUTES.LOGINWITH,
    component: AsyncLoginWith,
    type: routeTypes.UNAUTHENTICATED,
  },
  {
    path: BASE_ROUTES.REGISTRATION,
    component: AsyncRegistration,
    type: routeTypes.UNAUTHENTICATED,
  },
  {
    path: BASE_ROUTES.REGISTRATION_YOUTH_ATHLETE,
    component: AsyncRegistration,
    type: routeTypes.AUTHENTICATED,
  },
  {
    path: BASE_ROUTES.FORGOT_PASSWORD,
    component: AsyncForgotPassword,
    type: routeTypes.UNAUTHENTICATED,
  },
  {
    path: BASE_ROUTES.RESET_PASSWORD,
    component: AsyncResetPassword,
    type: routeTypes.UNAUTHENTICATED,
  },
  {
    path: BASE_ROUTES.DASHBOARD,
    component: AsyncDashboard,
    type: routeTypes.AUTHENTICATED,
  },
  {
    path: BASE_ROUTES.DASHBOARD_COMPLETE,
    component: AsyncDashboardComplete,
    type: routeTypes.AUTHENTICATED,
  },
  {
    path: BASE_ROUTES.PRIVACY_POLICY,
    component: AsyncPrivacyPolicy,
    type: routeTypes.PUBLIC,
  },
  {
    path: BASE_ROUTES.TERMS_OF_USE,
    component: AsyncTermsOfUse,
    type: routeTypes.PUBLIC,
  },
  {
    path: BASE_ROUTES.USER_AGREEMENT,
    component: AsyncUserAgreement,
    type: routeTypes.PUBLIC,
  },
  {
    path: BASE_ROUTES.PROFILE,
    component: AsyncProfile,
    type: routeTypes.AUTHENTICATED,
  },
  {
    path: BASE_ROUTES.PROFILE_EDIT,
    component: AsyncProfileEdit,
    type: routeTypes.AUTHENTICATED,
  },
  {
    path: BASE_ROUTES.MANAGE_YOUTH_ATHLETES_EDIT,
    component: AsyncEditYouthAthlete,
    type: routeTypes.AUTHENTICATED,
  },
  {
    path: BASE_ROUTES.SETTINGS,
    component: AsyncSettings,
    type: routeTypes.AUTHENTICATED,
  },
  {
    path: BASE_ROUTES.SETTINGS_EMAIL,
    component: AsyncSettingsEmailEdit,
    type: routeTypes.AUTHENTICATED,
  },
  {
    path: BASE_ROUTES.SETTINGS_CARD,
    component: AsyncSettingsCardEdit,
    type: routeTypes.AUTHENTICATED,
  },
  {
    path: BASE_ROUTES.SETTINGS_PASSWORD,
    component: AsyncSettingsPasswordEdit,
    type: routeTypes.AUTHENTICATED,
  },
  {
    path: BASE_ROUTES.MANAGE_YOUTH_ATHLETES,
    component: AsyncManageChildren,
    type: routeTypes.AUTHENTICATED,
  },
  {
    path: BASE_ROUTES.MANAGE_YOUTH_ATHLETES_VIEW,
    component: AsyncManageChildrenView,
    type: routeTypes.AUTHENTICATED,
  },
  // LEADERBOARD
  {
    path: LEADERBOARD_ROUTES.LEADERBOARD,
    component: AsyncLeaderboard,
    type: routeTypes.AUTHENTICATED,
  },
  {
    path: LEADERBOARD_ROUTES.MY_RESULTS,
    component: AsyncMyResults,
    type: routeTypes.AUTHENTICATED,
  },
  {
    path: LEADERBOARD_ROUTES.RESULTS,
    component: AsyncResults,
    type: routeTypes.AUTHENTICATED,
  },
  // NOMINATION
  {
    path: NOMINATION_ROUTES.NOMINATE,
    component: AsyncNominate,
    type: routeTypes.AUTHENTICATED,
  },
  {
    path: NOMINATION_ROUTES.NOMINATE_CLASS,
    component: AsyncNominateClass,
    type: routeTypes.AUTHENTICATED,
  },
  {
    path: NOMINATION_ROUTES.NOMINATE_SEARCH,
    component: AsyncNominateSearch,
    type: routeTypes.AUTHENTICATED,
  },
  {
    path: NOMINATION_ROUTES.NOMINATE_UPCOMING_EVENTS,
    component: AsyncUpcomingEvents,
    type: routeTypes.AUTHENTICATED,
  },
  {
    path: NOMINATION_ROUTES.NOMINATE_SUCCESS,
    component: AsyncNominateSuccess,
    type: routeTypes.AUTHENTICATED,
  },
  {
    path: NOMINATION_ROUTES.NOMINATE_CHECKOUT,
    component: AsyncNominateCheckout,
    type: routeTypes.AUTHENTICATED,
  },
  {
    path: NOMINATION_ROUTES.NOMINATE_REVIEW,
    component: AsyncNominateReview,
    type: routeTypes.AUTHENTICATED,
  },
  {
    path: NOMINATION_ROUTES.NOMINATE_PERFORMANCES,
    component: AsyncNominatePerformances,
    type: routeTypes.AUTHENTICATED,
  },
  {
    path: NOMINATION_ROUTES.NOMINATE_POINTS,
    component: AsyncNominatePoints,
    type: routeTypes.AUTHENTICATED,
  },
  // ENTRY
  {
    path: ENTRY_ROUTES.EVENT_ENTRY,
    component: AsyncEventEntry,
    type: routeTypes.AUTHENTICATED,
  },
  {
    path: ENTRY_ROUTES.EVENT_ENTRY_SEARCH,
    component: AsyncEventEntrySearch,
    type: routeTypes.AUTHENTICATED,
  },
  {
    path: ENTRY_ROUTES.EVENT_ENTRY_COLLECT_MEMBERSHIP_NUMBER,
    component: AsyncEventEntryCollectMembershipNumber,
    type: routeTypes.AUTHENTICATED,
  },
  {
    path: ENTRY_ROUTES.EVENT_ENTRY_COLLECT_MEMBERSHIP_FEES,
    component: AsyncEventEntryCollectMembershipFees,
    type: routeTypes.AUTHENTICATED,
  },
  {
    path: ENTRY_ROUTES.EVENT_ENTRY_YOUTH_ATHLETE_INFO,
    component: AsyncEventEntryYouthAthleteInfo,
    type: routeTypes.AUTHENTICATED,
  },
  {
    path: ENTRY_ROUTES.EVENT_ENTRY_LEGAL_INFO,
    component: AsyncEventEntryLegalInfo,
    type: routeTypes.AUTHENTICATED,
  },
  {
    path: ENTRY_ROUTES.EVENT_ENTRY_CHECKOUT,
    component: AsyncEventEntryCheckout,
    type: routeTypes.AUTHENTICATED,
  },
  {
    path: ENTRY_ROUTES.EVENT_ENTRY_SUCCESS,
    component: AsyncEventEntrySuccess,
    type: routeTypes.AUTHENTICATED,
  },
  {
    path: ENTRY_ROUTES.EVENT_ENTRY_UPCOMING_EVENTS,
    component: AsyncUpcomingEventsEntries,
    type: routeTypes.AUTHENTICATED,
  },
  {
    path: ENTRY_ROUTES.EVENT_ENTRY_UPCOMING_EVENT_ENTRIES,
    component: AsyncEventEntryTransactions,
    type: routeTypes.AUTHENTICATED,
  },
  {
    path: ENTRY_ROUTES.EVENT_ENTRY_PREFERENCES,
    component: AsyncEventEntryPreferences,
    type: routeTypes.AUTHENTICATED,
  },
  {
    path: ENTRY_ROUTES.EVENT_ENTRY_REVIEW,
    component: AsyncEventEntryReview,
    type: routeTypes.AUTHENTICATED,
  },
  // GIFT CARD
  {
    path: GIFT_CARD_ROUTES.GIFT_CARD_PREVIEW,
    component: AsyncDownloadGiftCardPreview,
    type: routeTypes.PUBLIC,
  },
  {
    path: GIFT_CARD_ROUTES.GIFTCARD_LIST,
    component: AsyncGiftCardList,
    type: routeTypes.AUTHENTICATED,
  },
  {
    path: GIFT_CARD_ROUTES.GIFTCARD_LOAD_BALANCE,
    component: AsyncGiftCardLoadBalance,
    type: routeTypes.AUTHENTICATED,
  },
];

const availableRoutes = () =>
  componentRouteMap.filter((cr) => routesMap()[cr.path]);

const getCategoryRoutes = (category) =>
  availableRoutes().filter((route) => {
    const categoryRoutes = arrayToObject(Object.values(routeCodes[category]));
    return categoryRoutes[route.path];
  });

export const nominationRoutes = () => getCategoryRoutes('NOMINATION_ROUTES');
export const entryRoutes = () => getCategoryRoutes('ENTRY_ROUTES');
export const baseRoutes = () =>
  [].concat(
    getCategoryRoutes('BASE_ROUTES'),
    getCategoryRoutes('LEADERBOARD_ROUTES'),
  );
export const giftCardRoutes = () => getCategoryRoutes('GIFT_CARD_ROUTES');
