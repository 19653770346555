import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selected: [],
  selectedCompetitions: [],
  selectedSegments: [],
  availableSegments: [],
  eventRank: null,
  newClasses: [],
};

export const disciplioneClassSlice = createSlice({
  name: 'disciplineClass',
  initialState: initialState,
  reducers: {
    saveSelectedClasses: (state, action) => {
      state.selected = action.payload;
    },

    saveNewClasses: (state, action) => {
      state.newClasses = action.payload;
    },

    saveRank: (state, action) => {
      state.eventRank = action.payload;
    },

    saveSelectedClassCompetitions: (state, action) => {
      state.selectedCompetitions = action.payload;
    },
    saveSelectedSegments: (state, action) => {
      state.selectedSegments = action.payload;
    },
    saveAvailableSegments: (state, action) => {
      state.availableSegments = action.payload;
    },
    removeSelectedClass: (state, action) => {
      const { className, disciplineUID } = action.payload;
      const filteredSegments = state.selectedSegments.filter(
        (ss) =>
          !(
            ss.className === className && ss.disciplineTypeUID === disciplineUID
          ),
      );
      const filteredCompetitions = state.selectedCompetitions.filter(
        (sc) =>
          !(sc.className === className && sc.disciplineId === disciplineUID),
      );
      const filteredClasess = state.selected.filter(
        (sc) => !(sc.value === disciplineUID && sc.ClassName === className),
      );
      state.selectedSegments = filteredSegments;
      state.selectedCompetitions = filteredCompetitions;
      state.selected = filteredClasess;
    },
    removeSelectedClassSegment: (state, action) => {
      const { disciplineUID, className, segment } = action.payload;
      const filteredSegment = state.selectedSegments.filter(
        (sc) =>
          sc.disciplineTypeUID !== disciplineUID ||
          sc.className !== className ||
          sc.segment.value !== segment.value,
      );
      state.selectedSegments = filteredSegment;
    },
    clearSelection: () => initialState,
  },
});

export const {
  saveSelectedClasses,
  saveRank,
  saveNewClasses,
  saveSelectedClassCompetitions,
  saveSelectedSegments,
  clearSelection,
  saveAvailableSegments,
  removeSelectedClassSegment,
  removeSelectedClass,
} = disciplioneClassSlice.actions;

export default disciplioneClassSlice.reducer;
