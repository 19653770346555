export const genderId = {
  MALE: 197,
  FEMALE: 198,
  OTHER: 207,
};

const genders = {
  [genderId.MALE]: 'Male',
  [genderId.FEMALE]: 'Female',
  [genderId.OTHER]: 'Prefer not to say',
};

export const gendersArray = [
  {
    value: genderId.MALE.toString(),
    label: genders[genderId.MALE],
  },
  {
    value: genderId.FEMALE.toString(),
    label: genders[genderId.FEMALE],
  },
  {
    value: genderId.OTHER.toString(),
    label: genders[genderId.OTHER],
  },
];

export const SSNDummyValue = '*****';

export const adultAge = 20;
export const taxInfoRenewPeriodInMonths = 12;
