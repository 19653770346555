import { authAsyncFail, authAsyncClearError } from './auth';
import api from 'api';

export const ADD_CHILD_REGISTRATION = 'ADD_CHILD_REGISTRATION';
export const REMOVE_CHILD_REGISTRATION = 'REMOVE_CHILD_REGISTRATION';
export const RESET_CHILDREN_REGISTRATION = 'RESET_CHILDREN_REGISTRATION';
export const ADD_USER_REGISTRATION = 'ADD_USER_REGISTRATION';
export const RESET_REGISTRATION = 'RESET_REGISTRATION';
export const SET_CURRENT_REGISTRATION_INFO = 'SET_CURRENT_REGISTRATION_INFO';
export const SET_CURRENT_REGISTRATION_CONSENT =
  'SET_CURRENT_REGISTRATION_CONSENT';
export const RESET_CURRENT_REGISTRATION = 'RESET_CURRENT_REGISTRATION';

export function setCurrentRegistrationInfo(registrationInfo) {
  return {
    type: SET_CURRENT_REGISTRATION_INFO,
    data: registrationInfo,
  };
}

export function setCurrentRegistrationConsent(registrationConsent) {
  return {
    type: SET_CURRENT_REGISTRATION_CONSENT,
    data: registrationConsent,
  };
}

export function resetCurrentRegistration() {
  return {
    type: RESET_CURRENT_REGISTRATION,
  };
}

export function addChildRegistration(child) {
  return {
    type: ADD_CHILD_REGISTRATION,
    data: child,
  };
}

export function removeChildRegistration(child) {
  return {
    type: REMOVE_CHILD_REGISTRATION,
    data: child,
  };
}

export function resetChildrenRegistration() {
  return {
    type: RESET_CHILDREN_REGISTRATION,
  };
}

export function addUserRegistration(user) {
  return async (dispatch) => {
    try {
      const email = user.email;
      const validateEmail = await api({
        path: `users/registerUser/isEmailValid?email=${email}`,
        method: 'GET',
      });
      if (validateEmail) {
        dispatch({
          type: ADD_USER_REGISTRATION,
          data: user,
        });
        dispatch(authAsyncClearError());
        return {
          isEmailValid: validateEmail,
          message: '',
        };
      }
    } catch (error) {
      dispatch(authAsyncFail(error.message));
      return {
        isEmailValid: false,
        message: error.message,
      };
    }
  };
}

export function resetRegistration(user) {
  return {
    type: RESET_REGISTRATION,
    data: user,
  };
}

export async function isEmailValid(email) {
  try {
    const validEmail = await api({
      path: `users/registerUser/isEmailValid?email=${email}`,
      method: 'GET',
    });
    return !!validEmail;
  } catch (error) {
    return false;
  }
}

export async function isPhoneValid(phone) {
  try {
    const validatePhone = await api({
      path: `users/registerUser/isPhoneValid?phone=${phone}`,
      method: 'GET',
    });
    return !!validatePhone;
  } catch (error) {
    return false;
  }
}
