import gql from 'graphql-tag';

export const REGISTER_USER = gql`
  mutation Register($input: RegistrationInputType!) {
    registerUser(input: $input) {
      user {
        id
        DOB
        Gender
        email
        ERAUID
        lastName
        firstName
        disciplineId
        phone
        street
        street2
        city
        state
        zip
        country
        socialTags
        nickname
        hometown
        careerEarnings
        worldChampionshipEarned
        height
        weight
        facebook
        twitter
        instagram
        LegalFirstName
        LegalLastName
        SSN
        USCitizen
        isParent
        isAthlete
        youthAthletes {
          id
          ERAUID
          FullName
          FName
          LName
          DOB
          TaxInfoUpdateDate
          Gender
          city
          state
          hometown
          entryData {
            confirmedEntries {
              EventUID
              entries {
                EPUID
                CompType
                HasAgreed
                PaymentStatus
                EventUID
                EventName
                EventDate
                EventDateTo
                EntryDateTo
                TimeZoneUID
                EventImage
                EventLocation
                rank
                isUpcoming
                isAlliancePartner
              }
            }
            pendingEntries {
              EventUID
              entries {
                EPUID
                CompType
                HasAgreed
                PaymentStatus
                EventUID
                EventName
                EventDate
                EventDateTo
                EntryDateTo
                TimeZoneUID
                EventImage
                EventLocation
                rank
                isUpcoming
                isAlliancePartner
                Pref1
                Pref2
                Pref3
                Out1
                Out2
                Pref1AsDate
                Pref2AsDate
                Pref3AsDate
                Out1AsDate
                Out2AsDate
                OutIfPrefNotMet
                partner {
                  EPUID
                  id
                  fullNameWithNickname
                  city
                  state
                }
              }
            }
            pendingWithMembershipEntries {
              EventUID
              entries {
                EPUID
                CompType
                HasAgreed
                PaymentStatus
                EventUID
                EventName
                EventDate
                EventDateTo
                EntryDateTo
                TimeZoneUID
                EventImage
                EventLocation
                rank
                isUpcoming
                isAlliancePartner
                Pref1
                Pref2
                Pref3
                Out1
                Out2
                Pref1AsDate
                Pref2AsDate
                Pref3AsDate
                Out1AsDate
                Out2AsDate
                OutIfPrefNotMet
                partner {
                  EPUID
                  id
                  fullNameWithNickname
                  city
                  state
                }
              }
            }
          }
        }
        entryData {
          pendingEntries {
            entries {
              EPUID
            }
          }
        }
      }
      token {
        expiresIn
        tokenType
        accessToken
        refreshToken
      }
      error {
        message
      }
    }
  }
`;
