import './utils/sentryInit';
import React from 'react';
import ReactDOM from 'react-dom';

import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import ReactGTM from 'react-gtm-module';

import 'foundation-sites/dist/css/foundation.min.css';

import 'assets/styles/app.scss';
import { App } from './App';
import FeatureFlagConfig from 'components/FeatureFlagConfig';

const googleAnalyticsKey = process.env.REACT_APP_GOOGLE_ANALYTICS_KEY;
ReactGA.initialize(googleAnalyticsKey);

const facebookPixelKey = process.env.REACT_APP_FACEBOOK_PIXEL_KEY;
const pixelMatching = {};
const pixelOptions = {
  autoConfig: true,
};
ReactPixel.init(facebookPixelKey, pixelMatching, pixelOptions);

const tagManagerOptions = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_KEY,
};
ReactGTM.initialize(tagManagerOptions);

const container = document.getElementById('root');

// At this part you can not use feature toggles

ReactDOM.render(
  <FeatureFlagConfig>
    <App />
  </FeatureFlagConfig>,
  container,
);
