import moment from 'moment';

export const userAgeVerify = (DOB) => {
  const formattedDOB = moment(DOB, 'MMDDYYYY').format('YYYY-MM-DD');

  const minAge = moment()
    .subtract(18, 'years')
    .add(1, 'd')
    .format('YYYY-MM-DD');

  return !moment(formattedDOB).isBefore(minAge);
};
