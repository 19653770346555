import api from 'api';

export const generateFakeJwt = async (email, masterEmail, accessToken) => {
  return await api({
    path: 'auth/auth0-master-user',
    method: 'POST',
    body: {
      masterEmail,
      email,
    },
    accessToken,
  });
};
