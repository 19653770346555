// @flow
import { Record } from 'immutable';
import type { DisciplineFeeType } from './DisciplineFee';
import type { SegmentType } from './Segment';
export type EventType = {
  id: ?string,
  eventType: ?string,
  EventFormat: ?number,
  BlockWomenSegment: ?boolean,
  BlockYouthSegment: ?boolean,
  name: ?string,
  EventName: ?string,
  dateRegistrationStart: ?string,
  dateRegistrationEnd: ?string,
  dateStart: ?string,
  dateEnd: ?string,
  eventDrawDate: ?string,
  firstPerformance: ?string,
  firstPerformanceRiding: ?string,
  isSlack: ?boolean,
  location: ?string,
  rank: ?number,
  rankLabel: ?string,
  performances: Object[],
  IsEntryExclusive: ?number,
  availableExclusiveDisciplines: Object[],
  eventImage: ?string,
  Website: ?string,
  entryFee: ?number,
  NumberOfPrefs: number,
  NumberOfNots: number,
  AllowOutIfPrefNotMet: ?number,
  TimeZoneUID: ?number,
  TimezoneNameIana: ?string,
  isAlliancePartner: ?boolean,
  EventAthleteAgreementURL: ?string,
  ShowStallsLink: number,
  EventRank: ?number,
  Associations: Object[],
  hasNominations: boolean,
  IsNomLastPerformance: number,
  DisciplineFees: Array<DisciplineFeeType>,
  entryCounts: Array<Object>,
  ShouldCollectTaxInfo: ?boolean,
  AllowClasses: ?boolean,
  AllowsPayLater: ?boolean,
  AllowsNomination: boolean,
  AllowsNominationOnEntry: boolean,
  AllowsBuddyGroup: boolean,
  nominatableOnEntrySegments: Array<SegmentType>,
  NominationOnEntryDiscountPercentage: ?number,
  nominationOnEntryFee: ?number,
  nominationFee: ?number,
  MaxNumberOfBuddies: number,
  DisablePartnerSelection: boolean,
  PrimaryAssociationUID: ?number,
  EventMessaging: ?string,
  ShowEntryCountOnPerformances: boolean,
  ShouldCollectSNNAndCitizenshipInfo: boolean,
  hasOpenMixedClass: boolean,
  hasYouthMixedClass: boolean,
  RequirePreferenceSelection: boolean,
  selectedYouthNomination: string,
};

const defaultValues: EventType = {
  availableExclusiveDisciplines: [],
  dateRegistrationStart: null,
  dateRegistrationEnd: null,
  dateStart: '',
  dateEnd: '',
  entryFee: null,
  eventDrawDate: null,
  eventType: null,
  eventImage: null,
  Website: null,
  BlockWomenSegment: false,
  BlockYouthSegment: false,
  firstPerformance: null,
  firstPerformanceRiding: null,
  id: null,
  isAlliancePartner: null,
  isSlack: null,
  location: null,
  name: null,
  purse: null,
  performances: [],
  rank: null,
  rankLabel: null,
  AllowOutIfPrefNotMet: null,
  Associations: [],
  EventAthleteAgreementURL: null,
  EventFormat: null,
  EventName: null,
  EventRank: null,
  IsEntryExclusive: 0,
  IsNomLastPerformance: 1,
  NumberOfPrefs: 0,
  NumberOfNots: 0,
  ShowStallsLink: 0,
  TimeZoneUID: null,
  TimezoneNameIana: null,
  hasNominations: false,
  DisciplineFees: [],
  entryCounts: [],
  ShouldCollectTaxInfo: true,
  AllowClasses: false,
  AllowsPayLater: false,
  AllowsNomination: false,
  AllowsNominationOnEntry: false,
  AllowsBuddyGroup: false,
  nominatableOnEntrySegments: [],
  NominationOnEntryDiscountPercentage: null,
  nominationOnEntryFee: null,
  nominationFee: null,
  MaxNumberOfBuddies: 2,
  DisablePartnerSelection: false,
  PrimaryAssociationUID: null,
  EventMessaging: null,
  ShowEntryCountOnPerformances: true,
  ShouldCollectSNNAndCitizenshipInfo: false,
  hasOpenMixedClass: false,
  hasYouthMixedClass: false,
  RequirePreferenceSelection: false,
  selectedYouthNomination: '',
};

export default class Event extends Record(defaultValues, 'Event') {
  constructor(values: ?EventType) {
    super({
      ...values,
    });
  }
}
