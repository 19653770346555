import moment from 'moment';

export const isUserYouth = (DOB) => {
  if (!DOB) {
    return false;
  }

  const maxYouthAge = 19;
  const currentAge = moment().diff(DOB, 'years', false);

  return currentAge <= maxYouthAge;
};
