const capitalizeNickname = (nickname = '') => {
  const nicknameWithoutQuotes = nickname.replace('"', '');

  return `"${nicknameWithoutQuotes
    .charAt(0)
    .toUpperCase()}${nicknameWithoutQuotes.substring(1)}`;
};

const capitalizeName = (name = '') => {
  return `${name.charAt(0).toUpperCase()}${name.substring(1)}`;
};

export const capitalizeString = (fullName = '') => {
  if (!fullName) {
    return '';
  }

  const names = fullName.toLowerCase().split(' ');
  return names
    .map((name) =>
      name.startsWith('"') ? capitalizeNickname(name) : capitalizeName(name),
    )
    .join(' ');
};
