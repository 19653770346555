import api from 'api';
import { getFromState, getAccessToken, logError } from 'helpers';

export const RESULT_ASYNC_START = 'RESULT_ASYNC_START';
export const RESULT_ASYNC_SUCCESS = 'RESULT_ASYNC_SUCCESS';
export const RESULT_ASYNC_FAIL = 'RESULT_ASYNC_FAIL';

function resultAsyncStart() {
  return {
    type: RESULT_ASYNC_START,
  };
}

function resultAsyncSuccess(data) {
  return {
    type: RESULT_ASYNC_SUCCESS,
    data,
  };
}

function resultAsyncFail(error) {
  return {
    type: RESULT_ASYNC_FAIL,
    error,
  };
}

export function getResults({
  discipline,
  ERAUID = null,
  segment,
  type,
  userId = null,
  isYouth,
}) {
  return async (dispatch, getState) => {
    const user = getFromState(getState, 'user');
    const isYouthContestant = isYouth ? JSON.parse(isYouth) : false;
    const { authPayload } = getFromState(getState, 'auth');
    const accessToken = getAccessToken(authPayload);

    dispatch(resultAsyncStart());
    try {
      const disciplineQuery = discipline
        ? `&discipline=${discipline.toString()}`
        : '';
      const shouldAddERAUIDQuery = !userId || userId === user.id;
      const ERAUIDQuery =
        ERAUID && (isYouthContestant || shouldAddERAUIDQuery)
          ? `&ERAUID=${ERAUID}`
          : '';
      const id = userId ? userId : user.id;

      const path = `users/results/${id}?segment=${segment}&type=${type}${disciplineQuery}${ERAUIDQuery}`;

      const response = await api({
        path,
        method: 'GET',
        accessToken,
      });

      dispatch(resultAsyncSuccess(response));
      return response;
    } catch (error) {
      logError(error);
      const err = 'There was a problem getting your results, please try again.';
      dispatch(resultAsyncFail(err));
      return null;
    }
  };
}
