// @flow
import get from 'lodash/get';
import type { RouterHistory } from 'react-router-dom';

type ChangeRouteType = {|
  history: RouterHistory,
  route: string,
  state?: Object,
  persistState?: boolean,
  replace?: boolean,
|};

export const changeRoute = (params: ChangeRouteType) => {
  const { history, route, persistState, replace } = params;

  const state = persistState
    ? { ...get(history, 'location.state'), ...params.state }
    : params.state;

  return replace ? history.replace(route, state) : history.push(route, state);
};
