// @flow
import EntryChargeRequest from 'models/EntryChargeRequest';
import Base from 'models/Base';
import { toEntityList } from 'models/BaseList';

import {
  ENTRY_CHARGE_ACTION_SET_TEAMMATEERAUID,
  ENTRY_CHARGE_ACTION_UNSET_TEAMMATEERAUID,
  ENTRY_CHARGE_ACTION_SUCCESS,
  ENTRY_CHARGE_ACTION_CLEAR,
  ENTRY_CHARGE_ASYNC_START,
  ENTRY_CHARGE_ASYNC_STOP,
  ENTRY_CHARGE_ASYNC_FAIL,
  ENTRY_CHARGE_ASYNC_SUCCESS,
  ENTRY_CHARGE_LIST_ASYNC_SUCCESS,
  ENTRY_CHARGE_ACTION_CLEAR_ERROR,
  ENTRY_PAYMENT_ACTION_SUCCESS,
  ENTRY_PAYMENT_ASYNC_START,
  ENTRY_PAYMENT_ASYNC_FAIL,
} from 'actions/entryChargeRequest';

const initialState = new Base({
  loading: false,
  errorMessage: '',
  data: new EntryChargeRequest(),
  list: toEntityList([], EntryChargeRequest),
});

const getEntryCharge = (state: Base): ?Object =>
  typeof state.data !== 'undefined' ? state.data : null;

const cloneEntryCharge = (entryChargeRequest: ?Object): Object =>
  entryChargeRequest ? Object.assign({}, entryChargeRequest.toJS()) : {};

const actionsMap = {
  [ENTRY_CHARGE_ACTION_SET_TEAMMATEERAUID]: (state, action) => {
    const TeammateERAUID = action.data;
    const entryChargeRequestObj = getEntryCharge(state);
    const entryChargeRequestObjClone = cloneEntryCharge(entryChargeRequestObj);
    entryChargeRequestObjClone.TeammateERAUID = TeammateERAUID;
    return state.merge({
      loading: false,
      data: new EntryChargeRequest(entryChargeRequestObjClone),
    });
  },
  [ENTRY_CHARGE_ACTION_UNSET_TEAMMATEERAUID]: (state) => {
    const entryChargeRequestObj = getEntryCharge(state);
    const entryChargeRequestObjClone = cloneEntryCharge(entryChargeRequestObj);
    entryChargeRequestObjClone.TeammateERAUID = null;
    return state.merge({
      loading: false,
      data: new EntryChargeRequest(entryChargeRequestObjClone),
    });
  },
  [ENTRY_CHARGE_ACTION_SUCCESS]: (state, action) => {
    return state.merge({
      loading: false,
      data: new EntryChargeRequest(action.data),
    });
  },
  [ENTRY_CHARGE_ACTION_CLEAR]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
      data: new EntryChargeRequest(),
    });
  },
  [ENTRY_CHARGE_ACTION_CLEAR_ERROR]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
    });
  },
  [ENTRY_CHARGE_ASYNC_START]: (state) => {
    return state.merge({
      loading: true,
      errorMessage: '',
    });
  },
  [ENTRY_CHARGE_ASYNC_STOP]: (state) => {
    return state.merge({
      loading: false,
      errorMessage: '',
    });
  },
  [ENTRY_CHARGE_ASYNC_SUCCESS]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: null,
      data: new EntryChargeRequest(action.data),
    });
  },
  [ENTRY_CHARGE_LIST_ASYNC_SUCCESS]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: null,
      list: toEntityList(action.data, EntryChargeRequest),
    });
  },
  [ENTRY_CHARGE_ASYNC_FAIL]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.error,
    });
  },
  [ENTRY_PAYMENT_ACTION_SUCCESS]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: null,
      data: new EntryChargeRequest(action.data),
    });
  },
  [ENTRY_PAYMENT_ASYNC_START]: (state) => {
    return state.merge({
      loading: true,
      errorMessage: '',
    });
  },
  [ENTRY_PAYMENT_ASYNC_FAIL]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.error,
    });
  },
};

export default function entryChargeRequest(
  state: Base = initialState,
  action: Object = {},
) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
