// @flow
import moment from 'moment-timezone';

/**
 * Function to take a date string in UTC format and assign a corresponding timezone
 * @param {string} date - The string date in format like: 2022-02-22T10:00:00.000Z
 * @param {string} timeZoneNameIana - timeZone that needs to be assigned to the date
 */
export const convertToDateWithTz = (date: string, timeZoneNameIana: string) => {
  const dateWithoutTzData = date.replace('Z', '');
  const result = moment.tz(dateWithoutTzData, timeZoneNameIana);
  return result;
};
