import gql from 'graphql-tag';

export const GET_DISCIPLINE_NOMINATABLE_SEGMENTS = gql`
  query GET_DISCIPLINE_NOMINATABLE_SEGMENTS($DisciplineTypeUIDs: [Int]!) {
    getDisciplinesNominatableBySegment(
      DisciplineTypeUIDs: $DisciplineTypeUIDs
    ) {
      Discipline {
        ID
        TypeUID
        Name
      }
      Segment {
        SegmentUID
      }
    }
  }
`;
