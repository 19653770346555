// @flow

export const getPartnersData = (partners: any) => {
  const label = 'Partner';
  if (!partners) return [];

  let partnersData: any = Object.keys(partners).map(
    (key: string, index: number) => {
      const id = partners[key].id;
      const name = partners[key].fullName ? partners[key].fullName : '';

      return {
        label: `${label} #${index + 1}`,
        name,
        index,
        id,
      };
    },
  );

  if (partnersData.length === 1) {
    partnersData[0].label = label;
  }

  return partnersData;
};
