import api from 'api';
import { getFromState, getAccessToken, logError } from 'helpers';

export const SEGMENTS_ASYNC_START = 'SEGMENTS_ASYNC_START';
export const SEGMENTS_ASYNC_FAIL = 'SEGMENTS_ASYNC_FAIL';
export const SEGMENTS_ASYNC_SUCCESS = 'SEGMENTS_ASYNC_SUCCESS';
export const SEGMENT_SELECT = 'SEGMENT_SELECT';

function segmentsAsyncStart() {
  return {
    type: SEGMENTS_ASYNC_START,
  };
}

function segmentsAsyncSuccess(data) {
  return {
    type: SEGMENTS_ASYNC_SUCCESS,
    data,
  };
}

export function segmentSelect(data) {
  return {
    type: SEGMENT_SELECT,
    data,
  };
}

function segmentsAsyncFail(error) {
  return {
    type: SEGMENTS_ASYNC_FAIL,
    error,
  };
}

export function getSegments({
  showExpiredSegments = false,
  hasOpen = false,
  hasYouth = false,
  hasChallenger = false,
  hasPro = false,
  womensOnly = false,
  active = true,
}) {
  return async (dispatch, getState) => {
    const { authPayload } = getFromState(getState, 'auth');
    const accessToken = getAccessToken(authPayload);
    dispatch(segmentsAsyncStart());

    const path = `
      segments?showExpiredSegments=${showExpiredSegments}
      &active=${active}
      &hasOpen=${hasOpen}
      &hasYouth=${hasYouth}
      &hasChallenger=${hasChallenger}
      &hasPro=${hasPro}
      &womensOnly=${womensOnly}
    `
      .replace(/\n/g, '')
      .replace(/\s+/g, '');

    try {
      const response = await api({
        path,
        method: 'GET',
        accessToken,
      });

      dispatch(segmentsAsyncSuccess(response));

      return response;
    } catch (error) {
      logError(error);
      const err = 'There was a problem getting your event, please try again.';
      dispatch(segmentsAsyncFail(err));
      return null;
    }
  };
}

export function getSegmentsByFilters({
  showExpiredSegments = false,
  isAdult = true,
  womensOnly = false,
  active = true,
}) {
  return async (dispatch, getState) => {
    const { authPayload } = getFromState(getState, 'auth');
    const accessToken = getAccessToken(authPayload);

    dispatch(segmentsAsyncStart());
    try {
      const path = `segments/by-filters?showExpiredSegments=${showExpiredSegments}&isAdult=${isAdult}&womensOnly=${womensOnly}&active=${active}`;

      const response = await api({
        path,
        method: 'GET',
        accessToken,
      });

      dispatch(segmentsAsyncSuccess(response));

      return response;
    } catch (error) {
      logError(error);
      const err =
        'There was a problem getting the segments, please try again later';
      dispatch(segmentsAsyncFail(err));
      return null;
    }
  };
}
