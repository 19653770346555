import ContestantDiscipline from 'models/ContestantDiscipline';
import { Record } from 'immutable';
import { toEntityList } from 'models/BaseList';

import {
  CONTESTANT_DISCIPLINE_BY_SEGMENT_ASYNC_START,
  CONTESTANT_DISCIPLINE_BY_SEGMENT_ASYNC_SUCCESS,
  CONTESTANT_DISCIPLINE_BY_SEGMENT_ASYNC_FAIL,
} from 'actions/contestantDiscipline';

const initialState = new Record({
  loading: false,
  errorMessage: '',
  data: new ContestantDiscipline(),
  list: toEntityList([], ContestantDiscipline),
})();

const actionsMap = {
  [CONTESTANT_DISCIPLINE_BY_SEGMENT_ASYNC_START]: (state) => {
    return state.merge({
      loading: true,
      errorMessage: '',
    });
  },
  [CONTESTANT_DISCIPLINE_BY_SEGMENT_ASYNC_FAIL]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.errorMessage,
    });
  },
  [CONTESTANT_DISCIPLINE_BY_SEGMENT_ASYNC_SUCCESS]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: null,
      data: new ContestantDiscipline(action.data),
      list: toEntityList(action.data.list, ContestantDiscipline),
    });
  },
};

export default function contestantDiscipline(
  state = initialState,
  action = {},
) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
