import AssociationMembership from 'models/AssociationMembership';
import Base from 'models/Base';
import get from 'lodash/get';

import {
  MEMBERSHIP_NUMBER_CHANGE,
  MEMBERSHIP_STATUS_SELECT,
  MEMBERSHIP_TYPE_FEE_SELECT,
  SET_MEMBERSHIP_FEES,
  CLEAN_MEMBERSHIP,
} from 'actions/associationMembership';

const initialState = new Base({
  data: new AssociationMembership(),
});

const actionsMap = {
  [MEMBERSHIP_NUMBER_CHANGE]: (state, action) => {
    const { data } = action;
    return state.merge({
      data: new AssociationMembership({
        ...data,
      }),
    });
  },

  [MEMBERSHIP_STATUS_SELECT]: (state, action) => {
    const { data } = action;
    return state.merge({
      data: new AssociationMembership({
        ...data,
      }),
    });
  },

  [MEMBERSHIP_TYPE_FEE_SELECT]: (state, action) => {
    const { data } = action;
    return state.merge({
      data: new AssociationMembership({
        ...data,
      }),
    });
  },

  [SET_MEMBERSHIP_FEES]: (state, action) => {
    const associations = action.data;

    const association = associations.find(
      (association) => association.CollectMembershipFees,
    );
    const membershipFees = get(association, 'AssociationFees', []);
    return state.merge({
      data: new AssociationMembership({
        membershipFees,
      }),
    });
  },
  [CLEAN_MEMBERSHIP]: () => initialState,
};

export default function associationMembership(
  state = initialState,
  action = {},
) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
