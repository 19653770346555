import get from 'lodash/get';
import BaseSelect from 'models/BaseSelect';

import {
  DISCIPLINE_SEGMENTS_ASYNC_START,
  DISCIPLINE_SEGMENTS_ASYNC_FAIL,
  DISCIPLINE_SEGMENTS_ASYNC_SUCCESS,
  DISCIPLINE_SEGMENT_SELECT,
  DISCIPLINE_SEGMENT_UNSELECT,
  DISCIPLINE_SEGMENT_RESET,
  REMOVE_DISCIPLINE_SEGMENT,
  DISCIPLINE_SEGMENT_DATA_RESET,
} from 'actions/disciplineSegments';

const initialState = new BaseSelect({
  loading: false,
  errorMessage: '',
  data: {},
  selected: {},
});

const actionsMap = {
  [DISCIPLINE_SEGMENT_SELECT]: (state, action) => {
    const { segment, disciplineTypeUID } = action.data;
    // Prevent duplicate selections from being added
    if (
      !get(state, `selected[${disciplineTypeUID}]`, []).some(
        (selected) => selected.value === segment.value,
      )
    ) {
      return state.addSelection(disciplineTypeUID, segment);
    }
    return state;
  },
  [DISCIPLINE_SEGMENT_UNSELECT]: (state, action) => {
    const { segment, disciplineTypeUID } = action.data;

    return state.removeSelection(disciplineTypeUID, (arr) =>
      arr.filter((selected) => selected.value !== segment.value),
    );
  },
  [DISCIPLINE_SEGMENTS_ASYNC_START]: (state) => {
    return state.merge({
      loading: true,
      errorMessage: '',
    });
  },
  [DISCIPLINE_SEGMENTS_ASYNC_SUCCESS]: (state, action) => {
    const disciplineSegments = get(action, 'data', []).reduce(
      (agg, disciplineSegment) => {
        if (!agg[disciplineSegment.Discipline.TypeUID]) {
          agg[disciplineSegment.Discipline.TypeUID] = [
            disciplineSegment.Segment.SegmentUID,
          ];
        } else {
          agg[disciplineSegment.Discipline.TypeUID].push(
            disciplineSegment.Segment.SegmentUID,
          );
        }
        return agg;
      },
      {},
    );

    return state.merge({
      loading: false,
      errorMessage: null,
      data: disciplineSegments,
    });
  },
  [DISCIPLINE_SEGMENTS_ASYNC_FAIL]: (state, action) => {
    return state.merge({
      loading: false,
      errorMessage: action.error,
    });
  },
  [DISCIPLINE_SEGMENT_RESET]: () => {
    return initialState;
  },
  [DISCIPLINE_SEGMENT_DATA_RESET]: (state) => {
    return state.update(`data`, () => {
      return {};
    });
  },
  [REMOVE_DISCIPLINE_SEGMENT]: (state, action) => {
    const { disciplineTypeUID } = action.data;
    return state.update(`selected`, (selectedMap) => {
      delete selectedMap[disciplineTypeUID];
      return selectedMap;
    });
  },
};

export default function disciplineSegments(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
