// @flow
import type { EntryType } from 'models/Entry';
import type { DisciplineType } from 'context/SelectedDisciplines/type';

const hasAllPreferenceKeys = (discipline: DisciplineType | EntryType) => {
  return ['Pref1', 'Pref2', 'Pref3', 'Out1', 'Out2'].every(
    (item) => discipline[item] !== undefined,
  );
};

export const preferencesAndOutCounts = (
  discipline: DisciplineType | EntryType,
) => {
  const { Pref1, Pref2, Pref3, Out1, Out2, hasPreference } = discipline;
  if (!hasPreference && !hasAllPreferenceKeys(discipline)) {
    return {
      preferenceCount: 0,
      outCount: 0,
    };
  }

  const preferenceCount = [Pref1, Pref2, Pref3].reduce((prev, curr) => {
    if (curr) {
      prev += 1;
    }
    return prev;
  }, 0);
  const outCount = [Out1, Out2].reduce((prev, curr) => {
    if (curr) {
      prev += 1;
    }
    return prev;
  }, 0);
  return { preferenceCount, outCount };
};
