import api from 'api';
import { getFromState, getAccessToken, logError } from 'helpers';

export const NOMINATIONS_ASYNC_START = 'NOMINATIONS_ASYNC_START';
export const NOMINATIONS_ASYNC_STOP = 'NOMINATIONS_ASYNC_STOP';
export const NOMINATIONS_ASYNC_SUCCESS = 'NOMINATIONS_ASYNC_SUCCESS';
export const NOMINATIONS_ASYNC_FAIL = 'NOMINATIONS_ASYNC_FAIL';

function nominationsAsyncStart() {
  return {
    type: NOMINATIONS_ASYNC_START,
  };
}

function nominationsAsyncSuccess(data) {
  return {
    type: NOMINATIONS_ASYNC_SUCCESS,
    data,
  };
}

function nominationsAsyncFail(error) {
  return {
    type: NOMINATIONS_ASYNC_FAIL,
    error,
  };
}

export function getMyNominations() {
  return async (dispatch, getState) => {
    const { id } = getFromState(getState, 'user');
    const { authPayload } = getFromState(getState, 'auth');
    const accessToken = getAccessToken(authPayload);

    dispatch(nominationsAsyncStart());
    try {
      const response = await api({
        path: `users/${id}/my-nominations`,
        method: 'GET',
        accessToken,
      });

      dispatch(nominationsAsyncSuccess(response));
      return response;
    } catch (error) {
      logError(error);
      const err =
        'There was a problem getting your nominations, please try again.';
      dispatch(nominationsAsyncFail(err));
      return null;
    }
  };
}
