import client from 'api/apollo';
import get from 'lodash/get';

import { GET_DISCIPLINE_NOMINATABLE_SEGMENTS } from 'queries/GetDisciplineNominatableSegments';

export const DISCIPLINE_SEGMENTS_ASYNC_START =
  'DISCIPLINE_SEGMENTS_ASYNC_START';
export const DISCIPLINE_SEGMENTS_ASYNC_FAIL = 'DISCIPLINE_SEGMENTS_ASYNC_FAIL';
export const DISCIPLINE_SEGMENTS_ASYNC_SUCCESS =
  'DISCIPLINE_SEGMENTS_ASYNC_SUCCESS';
export const DISCIPLINE_SEGMENT_SELECT = 'DISCIPLINE_SEGMENT_SELECT';
export const DISCIPLINE_SEGMENT_UNSELECT = 'DISCIPLINE_SEGMENT_UNSELECT';
export const DISCIPLINE_SEGMENT_RESET = 'DISCIPLINE_SEGMENT_RESET';
export const DISCIPLINE_SEGMENT_DATA_RESET = 'DISCIPLINE_SEGMENT_DATA_RESET';
export const REMOVE_DISCIPLINE_SEGMENT = 'REMOVE_DISCIPLINE_SEGMENT';

function disciplineSegmentAsyncStart() {
  return {
    type: DISCIPLINE_SEGMENTS_ASYNC_START,
  };
}

function disciplineSegmentsAsyncSuccess(data) {
  return {
    type: DISCIPLINE_SEGMENTS_ASYNC_SUCCESS,
    data,
  };
}

export function disciplineSegmentSelect(data) {
  return {
    type: DISCIPLINE_SEGMENT_SELECT,
    data,
  };
}

export function disciplineSegmentUnselect(data) {
  return {
    type: DISCIPLINE_SEGMENT_UNSELECT,
    data,
  };
}

export function disciplineSegmentsAsyncFail(error) {
  return {
    type: DISCIPLINE_SEGMENTS_ASYNC_FAIL,
    error,
  };
}

export function disciplineSegmentsReset() {
  return {
    type: DISCIPLINE_SEGMENT_RESET,
  };
}

export function disciplineSegmentsDataReset() {
  return {
    type: DISCIPLINE_SEGMENT_DATA_RESET,
  };
}

export const getDisciplinesNominatableBySegment = (selectedDisciplinesIds) => {
  return async (dispatch) => {
    if (!get(selectedDisciplinesIds, 'length', false)) {
      dispatch(disciplineSegmentsAsyncSuccess([]));
      return;
    }
    const getDisciplinesNominatableBySegmentQuery = {
      query: GET_DISCIPLINE_NOMINATABLE_SEGMENTS,
      variables: {
        DisciplineTypeUIDs: selectedDisciplinesIds.map(Number),
      },
      fetchPolicy: 'network-only',
    };

    dispatch(disciplineSegmentAsyncStart());
    const response = await client.query(
      getDisciplinesNominatableBySegmentQuery,
    );
    const data = get(response, `data.getDisciplinesNominatableBySegment`);
    dispatch(disciplineSegmentsAsyncSuccess(data));
  };
};

export const removeDisciplineSegment = (data) => {
  return {
    type: REMOVE_DISCIPLINE_SEGMENT,
    data,
  };
};
