import { getNestedProperty } from 'helpers';
const reducer = (accumulator, currentValue) =>
  accumulator +
  getNestedProperty('entryData.pendingEntries', currentValue, []).length;

export const getPendingEntriesCount = (user) => {
  if (!user) return 0;

  return (
    getNestedProperty('entryData.pendingEntries', user, []).length +
    getNestedProperty('youthAthletes', user, []).reduce(reducer, 0) +
    getNestedProperty('entryData.pendingWithMembershipEntries', user, []).length
  );
};
