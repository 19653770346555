import { getUserAthleteOption } from 'helpers/getUserAthleteOption';
import { isYouthRank } from '../constants/event';
import { nominateYouthAgeVerify } from 'helpers';
import { isUserFemale } from './isUserFemale';

export const getYouthAthletesLabel = (
  user,
  rank,
  shouldVerifyAge = false,
  shouldHideNomination = false,
) => {
  const youthAthletes = user.youthAthletes.map((athlete) => {
    const disabled = shouldVerifyAge
      ? nominateYouthAgeVerify(new Date(athlete.DOB))
      : false;
    const hideNomination = shouldHideNomination
      ? nominateYouthAgeVerify(new Date(athlete.DOB))
      : false;

    const isFemaleAthlete = athlete.Gender
      ? isUserFemale(athlete.Gender)
      : null;

    return {
      value: athlete.ERAUID,
      label: disabled
        ? `${athlete.FullName} \n must be at least 13 to nominate`
        : athlete.FullName,
      isYouthAthlete: true,
      isFemaleAthlete,
      disabled,
      hideNomination,
    };
  });

  if (isYouthRank(rank) && user.isParent) {
    return youthAthletes;
  }

  let parentAthlete = [getUserAthleteOption(user)].filter(
    (athlete) => athlete.isAthlete,
  );
  if (parentAthlete.length) {
    parentAthlete[0].isFemaleAthlete = isUserFemale(user.Gender);
    return [].concat(parentAthlete, youthAthletes);
  }
  return youthAthletes;
};
