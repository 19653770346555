/* global APP_CONFIG */
import fetch from 'node-fetch';
import { ApolloClient } from 'apollo-client';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { createPersistedQueryLink } from 'apollo-link-persisted-queries';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';

const base = APP_CONFIG.api.url;
const httpLink = new BatchHttpLink({
  uri: `${base.slice(0, base.length - 3)}/graphql`,
  credentials: 'same-origin',
  fetch,
});
const persistedQueryLink = createPersistedQueryLink();
const serverLink = persistedQueryLink.concat(httpLink);

const authLink = setContext((_, { headers }) => {
  try {
    const localStorageData = localStorage['persist:root'];
    const authJsonLocalStorage = localStorageData
      ? JSON.parse(localStorageData)
      : null;
    const authData = authJsonLocalStorage
      ? JSON.parse(authJsonLocalStorage.auth)
      : null;
    const token =
      authData && authData.data
        ? authData.data.authPayload.token.accessToken
        : null;

    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
  } catch (e) {
    // eslint-disable-next-line no-console
    console.info('Error:', e.message);
  }
});

const client = new ApolloClient({
  connectToDevTools: process.browser,
  shouldBatch: true,
  link: ApolloLink.from([authLink, serverLink]),
  cache: new InMemoryCache({
    addTypename: false,
  }),
});

export default client;
