// @flow

export type ValueLabelType = {|
  value: any,
  label: string,
|};

export const getValueLabelObject = (
  value: any,
  label: string,
): ValueLabelType => ({
  value,
  label,
});
